import React, { useState } from 'react'
import { Col, Form, FormFeedback, Input, Label, Row } from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import Select from 'react-select';
import { toast } from "react-toastify";
import generateExcel from './generateExcel'
import { withTranslation } from 'react-i18next';
import { VIDEO_APIS, CAMPAIGN_APIS } from '../../apis/apis';
import PlatformsDropdown from 'pages/DropDowns/PlatformsDropdown';
import { options } from 'toastr';

function getCurrentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed, so +1
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${hours}${minutes}${seconds}`;
}

const convertTimeStampTo12Hour = (timeStamp) => {
    const date = new Date(timeStamp);
    const year = date.getFullYear();
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedDate;
};

const ALL_CUSTOMERS = localStorage.getItem('customerName');
const ALL_PLATFORMS = "All Platforms";
const ALL_CAMPAIGNS = "All Campaigns";

function GenerateSocialMediaReport(props) {
    const [reportId, setReportId] = useState(1);
    const [loading, setLoading] = useState(false)
    const [clientName, setClientName] = useState(ALL_CUSTOMERS)
    const [platformName, setPlatformName] = useState(ALL_PLATFORMS)
    const [campaignName, setCampaignName] = useState(ALL_CAMPAIGNS)
    const validation = useFormik({
        // enableReinitialize: use this flag when initial values need to be changed
        enableReinitialize: true,

        initialValues: {
            // customer: "",
            // campaign: "",
            platform: "",
            start_date: "",
            end_date: "",
            report_name: "Reported & removed links - per platform",
        },
        validationSchema: Yup.object({
            // customer: [1].includes(reportId) ? Yup.string().required("Please select customer") : Yup.string().nullable(),
            platform: [1].includes(reportId) ? Yup.string().required("Please select platform") : Yup.string().nullable(),
            // campaign: /* [1].includes(reportId) ? Yup.string().required("Please select campaign") : */ Yup.string().nullable(),
            start_date: [1].includes(reportId) ? Yup.date().max(new Date(Date.now() - 86400000).toISOString().split("T")[0], "Start Date must me a date before today").required() : Yup.date().nullable(),
            end_date: [1].includes(reportId) ? Yup.date().max(new Date().toISOString().split("T")[0], "End Date must me a date before tomorrow")
                .min(Yup.ref('start_date'), "End Date can't be before the Start Date.").required() : Yup.date().nullable(),
            report_name: Yup.string().required("Please select a report")
        }),
        onSubmit: async (values, { resetForm }) => {
            setLoading(true);
            let [{ client, params, name, fields }] = reportsAvailable.filter(item => {
                return item['id'] == reportId;
            })



            toast.info("Generating report, Please wait");
            client(params).then((data) => {
                if (!data || !data.results || !data.results.length) {
                    toast.info("No Links");
                    return;
                }

                let formattedData = data.results.map((item) => {
                    return fields.reduce((acc, field) => {
                        const value = field.key.split('.').reduce((o, i) => (o && o[i] !== undefined) ? o[i] : null, item);
                        acc[field.label] = value;
                        if (field.label.includes('Date') && value !== null) {
                            acc[field.label] = convertTimeStampTo12Hour(value);
                            // console.log(acc[field.label]);
                        }
                        return acc;
                    }, {});
                });

                if (reportId === 1) {
                    formattedData = formattedData.map((item) => {
                        const updatedItem = { ...item };
                        updatedItem["Video Status (Removed/In progress)"] = item["Removal Date"] ? "Removed" : "In Progress";
                        return updatedItem;
                    })
                }

                toast.success("Downloading report, Please wait");
                generateExcel(formattedData, name);
                resetForm();
            }).catch((err) => {
                toast.error(err);
            }).finally(() => {
                setLoading(false);
            });
        },
    });

    const customStyles = {
        option: (base) => {
            return {
                ...base,
                backgroundColor: "white",
            };
        }
    };
    let reportsAvailable = [
        {
            id: 1,
            label: "Reported & removed links - per platform",
            params: {
                expand: ["campaign", "campaign.customer", "video_data.video_duration", "inserted_by", "video_data"].join(","),
                fields: ["url", "keyword", "campaign.campaign_name", "campaign.customer.customer_name", "video_data.video_total_views", "video_data.video_duration.duration", "video_data.video_type", "video_data.comments", "review_date", "report_date", "remove_date", "inserted_by.email"].join(","),
                page: '*',
                reviewed: true,
                reported: true,
                report_date__gte: validation.values.start_date,
                report_date__lte: validation.values.end_date,
                campaign: validation.values.campaign,
                platform: validation.values.platform,
                // customer: validation.values.customer
            },
            client: VIDEO_APIS.list,
            fields: [
                {
                    label: "Detected Date",
                    key: "review_date"
                },
                {
                    label: "Campaign",
                    key: "campaign.campaign_name"
                },
                {
                    label: "Video URL",
                    key: "url"
                },
                {
                    label: "Keyword",
                    key: "keyword"
                },
                {
                    label: "Views",
                    key: "video_data.video_total_views"
                },
                {
                    label: "Video Duration",
                    key: "video_data.video_duration.duration"
                },
                {
                    label: "Video Type (isolated/playlist)",
                    key: "video_data.video_type"
                },
                {
                    label: "Comments",
                    key: "video_data.comments"
                },
                {
                    label: "Reported Member",
                    key: "inserted_by.email"
                },
                {
                    label: "Report Date",
                    key: "report_date"
                },
                {
                    label: "Removal Date",
                    key: "remove_date"
                }
            ],
            name: `${clientName}_${platformName}_${campaignName}_${validation.values.start_date}__${validation.values.end_date}_${getCurrentDateTime()}`
        },
        // {
        //     id: 2,
        //     label: "All Campaigns",
        //     params: {
        //         expand: ["customer", "campaign_type"].join(","),
        //         fields: ["id",
        //             "campaign_name",
        //             "customer.customer_name",
        //             "campaign_status",
        //             "campaign_type.type",
        //             "sample_of_work",
        //             "number_of_seasons",
        //             "number_of_episodes",
        //             "campaign_loa_dropbox_url",
        //             "start_date",
        //             "end_date",
        //             "campaign_rights_owner",
        //             "ownership_territory",
        //             "policy"
        //         ].join(","),
        //         page: '*',
        //     },
        //     client: CAMPAIGN_APIS.list,
        //     fields: [
        //         {
        //             label: "Campaign ID",
        //             key: "id"
        //         },
        //         {
        //             label: "Campaign Name",
        //             key: "campaign_name"
        //         },
        //         {
        //             label: "Customer Name",
        //             key: "customer.customer_name"
        //         },
        //         {
        //             label: "Campaign Status",
        //             key: "campaign_status"
        //         },
        //         {
        //             label: "Campaign Type",
        //             key: "campaign_type.type"
        //         },
        //         {
        //             label: "Sample of work",
        //             key: "sample_of_work"
        //         },
        //         {
        //             label: "Number of seasons",
        //             key: "number_of_seasons"
        //         },
        //         {
        //             label: "Number of episodes",
        //             key: "number_of_episodes"
        //         },
        //         {
        //             label: "Campaign LOA",
        //             key: "campaign_loa_dropbox_url"
        //         },
        //         {
        //             label: "Campaign Start Date",
        //             key: "start_date"
        //         },
        //         {
        //             label: "Campaign End Date",
        //             key: "end_date"
        //         },
        //         {
        //             label: "Campaign Rights Owner",
        //             key: "campaign_rights_owner"
        //         },
        //         {
        //             label: "Ownership territory",
        //             key: "ownership_territory"
        //         },
        //         {
        //             label: "Policy",
        //             key: "policy"
        //         }
        //     ],
        //     name: `All_Campaigns_${getCurrentDateTime()}`
        // },
    ];

    return (
        <div className="page-content">
            <div className="container-fluid" style={{ minHeight: '500px' }}>
                <Form
                    onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                    }}
                >
                    <Row>
                        <div className="mb-3">
                            <Label htmlFor="report_name">Select Report</Label>
                            <Select
                                onChange={selectedOption => {
                                    setReportId(selectedOption.id);
                                    return validation.setFieldValue("report_name", selectedOption.label);
                                }}
                                className={`basic-multi-select ${validation.touched.report_name && validation.errors.report_name ? "invalid" : ""}`}
                                name='report_name'
                                id='report_name'
                                classNamePrefix="select"
                                value={reportsAvailable.filter(x=>x.id === reportId)}
                                defaultValue={reportsAvailable.filter(x=>x.id === reportId)}
                                onBlur={validation.handleBlur}
                                styles={customStyles}
                                aria-invalid={
                                    validation.touched.report_name && validation.errors.report_name ? true : false
                                }
                                required
                                options={reportsAvailable} />
                            {validation.touched.report_name && validation.errors.report_name ? (
                                <div className="error-message">{validation.errors.report_name}</div>
                            ) : null}
                        </div>
                    </Row>

                    {[1].includes(reportId) && <Row>
                        <div className="mb-3">
                            <PlatformsDropdown onChange={selectedOption => {
                                setPlatformName(selectedOption.label || ALL_PLATFORMS)
                                return validation.setFieldValue("platform", selectedOption?.value || "")
                            }}
                                onBlur={validation.handleBlur}
                                value={validation.values.platform || ""}
                                translate={props.t}
                                validation={validation}
                            />
                        </div>
                    </Row>}
                    {/* {[2].includes(reportId) && <Row>
                    <div className="mb-3">
                        <CampaignsDropdown onChange={selectedOption => {
                            setCampaignName(selectedOption.label || ALL_CAMPAIGNS)
                            return validation.setFieldValue("campaign", selectedOption.value)
                        }}
                            onBlur={validation.handleBlur}
                            value={validation.values.campaign || ""}
                            validation={validation}
                            translate={props.t}
                            customer_id={validation.values.customer}
                        />
                    </div>
                </Row>} */}
                    {[1].includes(reportId) &&
                        <Row className='d-flex'>
                            <Col lg={2} md={3} sm={4} xs={6}>
                                <div className="mb-3">
                                    <Label htmlFor="description">Start Date</Label>
                                    <Input
                                        type="date"
                                        className="form-control"
                                        id="start_date"
                                        placeholder="Choose Report Start Date"
                                        name='start_date'
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.start_date || ""}
                                        invalid={
                                            validation.touched.start_date && validation.errors.start_date ? true : false
                                        }
                                    />
                                    {validation.touched.start_date && validation.errors.start_date ? (
                                        <FormFeedback type="invalid">{validation.errors.start_date}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                            <Col lg={2} md={3} sm={4} xs={6}>
                                <div className="mb-3">
                                    <Label htmlFor="description">End Date</Label>
                                    <Input
                                        type="date"
                                        className="form-control"
                                        id="end_date"
                                        placeholder="Choose Report End Date"
                                        name='end_date'
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.end_date || ""}
                                        invalid={
                                            validation.touched.end_date && validation.errors.end_date ? true : false
                                        }
                                    />
                                    {validation.touched.end_date && validation.errors.end_date ? (
                                        <FormFeedback type="invalid">{validation.errors.end_date}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                    }
                    <div>
                        <button type="submit" className="btn btn-primary w-md" disabled={loading}>
                            Submit
                        </button>
                    </div>
                </Form>

            </div>
        </div>

    )
}

export default withTranslation()(GenerateSocialMediaReport)