import React, { useEffect } from "react"
import ReactApexChart from "react-apexcharts"
import { useQuery } from "@tanstack/react-query";
import fetchAPI from "helpers/fetcher/fetchClient";
import { getCustomerId } from "helpers/auth/getUserId";
import Loading from "components/Common/loading/Loading";


const convertDateToString = (date) => {
  // the date is in the format of yyyy-mm
  // we need to convert it to MonthName yyyy
  const year = date.slice(0, 4);
  const month = date.slice(5, 7);
  let monthName = new Date(year, month - 1).toLocaleString('en-us', { month: 'long' });
  // shorten the month name
  monthName = monthName.slice(0, 3); // January -> Jan
  // shorten the year
  let yearShort = year.slice(2, 4); // 2021 -> 21
  return monthName + ' ' + yearShort;
};

const LineColumnArea = ({data, selectedOption='detected'}) => {

  const [detectedSeries, setDetectedSeries] = React.useState([]);
  const [removedSeries, setRemovedSeries] = React.useState([]);
  const [dataCategories, setDataCategories] = React.useState([]);
  const colorMap = {
    search_engines: "#3aa757",
    dailymotion: "#365486",
    okru: "#FFA447",
    bing: "#52D3D8",
    google: "#D92929",
    telegram: "#1a1aff",
    vimeo: "#40A2D8",
    facebook: '#3081D0',
    instagram: '#FFC47E',
    twitter: '#ffffff',
    youtube: '#C70039',
    tiktok: '#3D30A2',
    cyberlocker: '#ffffff'
  };



  useEffect(() => {
    if (data) {
      let tmpCategories = [];
        let tmpDetectedSeries = [];
        let tmpRemovedSeries = [];
        Object.entries(data).forEach(([service, months]) => {
          if (service === 'overall') return;
          let seriesName = service;
          let detectedSeriesData = [];
          let removedSeriesData = [];
          Object.entries(months).forEach(([date, statictics]) => {
            if (date === 'overall') return;
            tmpCategories.push(convertDateToString(date));
            Object.entries(statictics).forEach(([type, total_links]) => {
              if (type === 'reported') {
                detectedSeriesData.push(total_links);
              }
              if (type === 'removed') {
                removedSeriesData.push(total_links);
              }
            })
          })
          tmpDetectedSeries.push({name: seriesName, data: detectedSeriesData , color: colorMap[service]});
          tmpRemovedSeries.push({name: seriesName, data: removedSeriesData, color: colorMap[service]});
        })
        
        setDataCategories(tmpCategories);
        setDetectedSeries(tmpDetectedSeries);
        setRemovedSeries(tmpRemovedSeries);
    }
  }, [data, selectedOption])



  const series = (selectedOption === 'detected' ? detectedSeries : removedSeries) || [];
  const options = {
    chart: {
      toolbar: {
        show: false
      },
    },
    dataLabels: {
        enabled: false,
    },
    stroke: {
        curve: "smooth",
        width: 1,
    },
    xaxis: {
        type: "date",
        categories: dataCategories,
        range: 11,
    },
    yaxis: {
      title: {
        text: "Links",
      },
    },
    grid: {
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
        borderRadius: 0,
        colors: {
          // Set the color to transparent to remove the border
          backgroundBarColors: ["transparent"],
        },
      },
    },
    tooltip: {
        x: {
            format: "dd/MM/yy HH:mm",
        },
        
    },
    fill: {
      opacity: 1,
    }
}

  if (!data) {
    return <Loading />;
  }
  return (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height="350px"
    />
  )
}

export default LineColumnArea
