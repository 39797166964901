import React from 'react'
import { Card, CardBody, CardTitle, Input } from 'reactstrap';
import MonthlyDetectedRemovedGraph from './helpers/MonthlyDetectedRemovedGraph';
import DarkSkeleton from './helpers/loading/DarkSkeleton';


function MonthlyDetectedRemoved({ data, numOfDays, setNumOfDays }) {
    return (
        <Card>
            <CardBody>
                <CardTitle className="mb-4">
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        <div>Daily Reported / Removed</div>
                        <Input
                            style={{
                                width: "30%"
                            }}
                            type="select"
                            className="mt-2"
                            value={numOfDays}
                            onChange={(e) => setNumOfDays(e.target.value)}>
                            <option value="7">Last 7 days</option>
                            <option value="14">Last 14 days</option>
                            <option value="30">Last 30 days</option>
                        </Input>
                    </div>

                </CardTitle>
                {data ? <MonthlyDetectedRemovedGraph data={data} dataColors='["--bs-danger","--bs-success"]' numOfDays={numOfDays} setNumOfDays={setNumOfDays} /> : <DarkSkeleton height={300} />}
            </CardBody>
        </Card>
    )
}

export default MonthlyDetectedRemoved