import React, { useState } from "react";
import { Row, Col, Card, CardBody, Button, ButtonGroup, Input, Badge } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import DarkSkeleton from "../Dashboard/MainComponents/helpers/loading/DarkSkeleton";
import { CAMPAIGN_APIS } from "../../apis/apis";
export default function Campaigns() {
  const [searchTerm, setSearchTerm] = useState("");
  const [activeFilter, setActiveFilter] = useState("all"); // default
  const [searchBy, setSearchBy] = useState("campaign_name"); // default
  let navigate = useNavigate();
  const CampaignsQuery = useQuery({
    queryKey: ['Campaigns-analytics'],
    queryFn: async () => await CAMPAIGN_APIS.list({
      page: '*',
      expand: ['customer'],
      fields: [
        'id',
        'campaign_name',
        'campaign_status',
        'campaign_poster_path',
        'customer.customer_logo_path'
      ].join(','),
      ordering: 'campaign_name'
    })
  })


  if (CampaignsQuery.isLoading) {
    return (
      <DarkSkeleton height={500} />
    );
  }


  if (CampaignsQuery?.data?.results?.length === 0) {
    return (
      <div style={{ textAlign: "center", margin: "20px", width: "100%" }}>
        <h3>No campaigns found.</h3>
      </div>
    );
  }


  const filteredCampaigns = CampaignsQuery?.data?.results?.filter(campaign => {
    if (activeFilter === "all") {
      return campaign[searchBy].toLowerCase().includes(searchTerm.toLowerCase())
    }
    else if (activeFilter === "active") {
      return campaign[searchBy].toLowerCase().includes(searchTerm.toLowerCase()) && campaign?.campaign_status === true
    }
    else {
      return campaign[searchBy].toLowerCase().includes(searchTerm.toLowerCase()) && campaign?.campaign_status === false
    }
  }
  );

  return (
    <React.Fragment>
      <div className="page-content" style={{ backgroundColor: "#2a3042" }}>

        <Row style={{ margin: 0, justifyContent: 'space-between' }}>
          <Col md={8}>
            <Input
              id="campaignsFilter"
              type="text"
              className="form-control"
              placeholder={`Search by ${searchBy === 'campaign_rights_owner' ? 'Customer' : 'Name'}...`}
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />
          </Col>
          <Col md={4}>
            <ButtonGroup>
              <Button
                color="primary"
                outline
                onClick={() => setActiveFilter('all')}
                active={activeFilter === 'all'}
              >
                All
              </Button>
              <Button
                color="success"
                outline
                onClick={() => setActiveFilter('active')}
                active={activeFilter === 'active'}
              >
                Active Campaigns
              </Button>
              <Button
                color="danger"
                outline
                onClick={() => setActiveFilter('inactive')}
                active={activeFilter === 'inactive'}
              >
                Inactive Campaigns
              </Button>
            </ButtonGroup>
          </Col>
        </Row>

        <br />
        <Row style={{ margin: 0, justifyContent: 'flex-start', minHeight: '400px' }}>
          {
            filteredCampaigns.length === 0 ? (
              <div style={{ textAlign: "center", margin: "20px", width: "100%" }}>
                <h3>No matching campaigns found.</h3>
              </div>
            ) : (
              filteredCampaigns.map(campaign => (
                <Col lg={2} key={campaign.id} style={{ flex: '0 0 20%' }}>
                  <Link to={ `/campaigns-analytics/${campaign?.id}`}>
                    <Card
                      style={{
                        height: "80%",
                        borderRadius: "5px",
                        cursor: "pointer"
                      }}
                    >
                      <img
                        src={campaign.campaign_poster_path}
                        className="card-img-top"
                        alt="..."
                        style={{
                          maxWidth: "100%", // Set the width to 100% to ensure it takes up the full width of the container
                          height: "100%", // Set a fixed height for the image
                          objectFit: "cover", // This will scale the image while maintaining its aspect ratio
                          borderRadius: "5px",
                          borderTopLeftRadius: "5px",
                          objectFit: "cover",
                          maxHeight: "80%",
                        }}
                      />
                      <CardBody>
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="d-flex justify-content-start align-items-center mb-0" style={{
                            height: 20
                          }}>
                            {campaign?.campaign_name.toUpperCase()}
                          </p>
                          <Badge color={campaign?.campaign_status === true ? "success" : "danger"}>{campaign?.campaign_status === true ? "Active" : "Inactive"}</Badge>
                          <div>
                            <img
                              src={campaign?.customer?.customer_logo_path}
                              alt="..."
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "50%",
                                objectFit: "cover",
                                objectPosition: "center",
                                display: "inline-block",
                                marginRight: "0"
                              }}
                            />
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
              )
              ))}
        </Row>
      </div >
    </React.Fragment>
  );
}
