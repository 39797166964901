import { useQuery } from '@tanstack/react-query';
import fetchAPI from 'helpers/fetcher/fetchClient';
import React, { useRef } from 'react'
import Select from 'react-select';
import { Label } from 'reactstrap';

function CampaignDropDown({ onChange, onBlur, value, validation, customer_id = null, optional = false }) {

    let campaignQuery = useQuery({
        queryKey: ["campaign", customer_id],
        queryFn: () => fetchAPI.get(process.env.REACT_APP_CAMPAIGNS_MANAGEMENT_URL + `/campaigns?page=*&fields=id,campaign_name${customer_id ? "&customer_id=" + customer_id : ""} `, process.env.REACT_APP_CAMPAIGNS_MANAGEMENT_TOKEN)
    })
    const campaignOptions = campaignQuery.data?.results?.map((item) => ({
        label: item.campaign_name,
        value: item.id,
      })) || [];
      if (optional)
        {
            campaignOptions.splice(0, 0, { label: "All", value: null });
        }
      
    const customStyles = {
        option: (base) => {
            return {
                ...base,
                backgroundColor: "white",
            };
        }
    };
    return (
        <div>
            <Label htmlFor="campaign_name">Campaign</Label>
            <Select cacheOptions defaultOptions onChange={onChange}
                className={`basic-multi-select ${validation.touched.campaign_name && validation.errors.campaign_name ? "invalid" : ""}`}
                classNamePrefix="select"
                onBlur={onBlur}
                value={campaignOptions.find((option) => option.value === value)}
                styles={customStyles}
                isLoading={campaignQuery.isLoading}
                aria-invalid={
                    validation.touched.campaign_name && validation.errors.campaign_name ? true : false
                }
                options={campaignOptions} />
                {validation.touched.campaign_name && validation.errors.campaign_name ? (
                <div className="error-message">{validation.errors.campaign_name}</div>
            ) : null}
        </div>
    )
}

export default CampaignDropDown