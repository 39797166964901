import PropTypes from 'prop-types';
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { layoutTypes } from "./constants/layout";
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes";

// Import all middleware
import Authmiddleware from "./routes/route";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
// Import scss
import "./assets/scss/theme.scss";
import "style.css"
import fetchAPI from 'helpers/fetcher/fetchClient';

const getLayout = (layoutType) => {
  let Layout = VerticalLayout;
  // switch (layoutType) {
  //   case layoutTypes.VERTICAL:
  //     Layout = VerticalLayout;
  //     break;
  //   case layoutTypes.HORIZONTAL:
  //     Layout = HorizontalLayout;
  //     break;
  //   default:
  //     break;
  // }
  return Layout;
};

const App = () => {


  useEffect(() => {


    const token = localStorage.getItem("social_access_token")
    const fetch = async () => {
      if (token) return
      const cred = await fetchAPI.get(process.env.REACT_APP_USERS_MANAGEMENT_URL + "/token/exchange/")
      localStorage.setItem("social_access_token", cred.access_token)
      localStorage.setItem("social_refresh_token", cred.refresh_token)
      location.reload()
    }
    try {
      fetch()
      window.addEventListener('storage', fetch);
      return () => {
        window.removeEventListener('storage', fetch);
      };
    } catch (error) {
      console.error(error.message)
    }
  }, [])

  const { layoutType } = useSelector((state) => ({
    layoutType: state.Layout.layoutType,
  }));

  const Layout = getLayout(layoutType);

  return (
    <React.Fragment>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <NonAuthLayout>
                {route.component}
              </NonAuthLayout>
            }
            key={idx}
            exact={true}
          />
        ))}

        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                <Layout>{route.component}</Layout>
              </Authmiddleware>}
            key={idx}
            exact={true}
          />
        ))}
      </Routes>
      <ToastContainer />
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

export default App;