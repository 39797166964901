import React, { useState } from "react";
import { Row, Col, Card, CardBody, Button, ButtonGroup, Input, Badge } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { enc } from "crypto-js";
import Loading from "components/Common/loading/Loading";

export function Campaigns({
  topCampaignsData,
  isLoading = false
}) {
  if (isLoading) {
    return (
      <Loading />
    );
  }
  if (topCampaignsData.length === 0 ) {
    return (
      <div style={{ textAlign: "center", margin: "20px", width: "100%" }}>
        <h3>No campaigns found.</h3>
      </div>
    );
  }
  const [searchTerm, setSearchTerm] = useState("");
  const [activeFilter, setActiveFilter] = useState("all"); // default
  const [searchBy, setSearchBy] = useState("campaign_name"); // default
  let navigate = useNavigate();

  const filteredCampaigns = topCampaignsData.filter(campaign => {
    if (activeFilter === "all") {
      return campaign[searchBy].toLowerCase().includes(searchTerm.toLowerCase())
    }
    else if (activeFilter === "active") {
      return campaign[searchBy].toLowerCase().includes(searchTerm.toLowerCase()) && campaign.status === 'Active'
    }
    else {
      return campaign[searchBy].toLowerCase().includes(searchTerm.toLowerCase()) && campaign.status === 'Inactive'
    }
  }
  );

  return (
    <div>
      <Row style={{ margin: 0, justifyContent: 'space-between' }}>
        <Col md={8}>
          <Input
            id="campaignsFilter"
            type="text"
            className="form-control"
            placeholder={`Search by ${searchBy === 'campaign_rights_owner' ? 'Customer' : 'Name'}...`}
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
        </Col>
        <Col md={4}>
          <ButtonGroup>
            <Button
              color="primary"
              outline
              onClick={() => setActiveFilter('all')}
              active={activeFilter === 'all'}
            >
              All
            </Button>
            <Button
              color="success"
              outline
              onClick={() => setActiveFilter('active')}
              active={activeFilter === 'active'}
            >
              Active Campaigns
            </Button>
            <Button
              color="danger"
              outline
              onClick={() => setActiveFilter('inactive')}
              active={activeFilter === 'inactive'}
            >
              Inactive Campaigns
            </Button>
          </ButtonGroup>
        </Col>
      </Row>

      <br />
      <Row style={{ margin: 0, justifyContent: 'flex-start' ,minHeight: '400px'}}>
        {
          filteredCampaigns.length === 0 ? (
            <div style={{ textAlign: "center", margin: "20px", width: "100%" }}>
              <h3>No matching campaigns found.</h3>
            </div>
          ) : (
            filteredCampaigns.map(campaign => (
              <Col lg={2} key={campaign.id} style={{ flex: '0 0 20%' }}>
                <Card
                  style={{
                    height: "80%",
                    borderRadius: "5px",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    let encryptedCampaignId = enc.Utf8.parse(campaign.id.toString());
                    navigate(`/campaigns/${campaign.id}`)
                  }
                  }
                >
                  <img
                    src={campaign.campaign_poster_photo_id ? `${campaign.campaign_poster_photo_id.name}` : "www.myhappyenglish.com/x9walos9f/uploads/2013/05/placeholder1.png"}
                    className="card-img-top"
                    alt="..."
                    style={{
                      maxWidth: "100%", // Set the width to 100% to ensure it takes up the full width of the container
                      height: "100%", // Set a fixed height for the image
                      objectFit: "cover", // This will scale the image while maintaining its aspect ratio
                      borderRadius: "5px",
                      borderTopLeftRadius: "5px",
                      objectFit: "cover",
                      maxHeight: "80%",
                    }}
                  />
                  <CardBody>
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="d-flex justify-content-start align-items-center mb-0" style={{
                        height: 20
                      }}>
                        {campaign.campaign_name.toUpperCase()}
                      </p>
                      <Badge color={campaign.status === 'Active' ? "success" : "danger"}>{campaign.status}</Badge>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )
            ))}
      </Row>
    </div >
  );
}
