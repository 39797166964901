export const getUserId = () => {
    let user = localStorage.getItem('authUser')
    let { id } = JSON.parse(user)
    return id
}

export const getCustomerId = () => {
    let customerId = localStorage.getItem('customerId')
    return customerId
}

export const getCustomerName = () => {
    let customerName = localStorage.getItem('customerName')
    return customerName
}