import React, { useEffect } from 'react';
import { Badge, Card, CardBody, Col, Row } from 'reactstrap';
import '../ServicesData.css';
import youtube_logo from '../../../assets/images/services/youtube_logo.png';
import facebook_logo from '../../../assets/images/services/facebook_logo.png';
import tiktok_logo from '../../../assets/images/services/tiktok_logo.png';
import instagram_logo from '../../../assets/images/services/instagram_logo.png';
import twitter_logo from '../../../assets/images/services/twitter_logo.png';
import twitch_logo from '../../../assets/images/services/twitch_logo.png';
import snapchat_logo from '../../../assets/images/services/snapchat_logo.webp';
import DarkSkeleton from './helpers/loading/DarkSkeleton';

const ServicesData = ({ data, main=false }) => {

    const [servicesInfo, setServicesInfo] = React.useState([]);
    const [activeServices, setActiveServices] = React.useState([]);

    const serviceLogos = {
        Tiktok: tiktok_logo,
        Youtube: youtube_logo,
        Facebook: facebook_logo,
        Instagram: instagram_logo,
        Twitter: twitter_logo,
        Twitch: twitch_logo,
        Snapchat: snapchat_logo
    }; 

    const services = Object.keys(serviceLogos);

    const LoadingCards = []
    Object.entries(serviceLogos).forEach(([key, value]) => {
        LoadingCards.push(
            <Col lg={3} key={key}>
                <Card >
                    <CardBody className="p-2 pt-4 pd-4" style={{ minHeight: "210px", width:'100%', marginRight: "5px" }} >
                        <div className="text-center mb-3">
                            <img src={value} alt="" className="avatar-sm" />
                            <h5 className="mt-4 mb-2 font-size-15">{key.toUpperCase()}</h5>
                            <p className="mb-0 text-muted"> Reported Links</p>
                            <DarkSkeleton count={1} width={'35%'} />
                            <p className="mb-0 text-muted"> REMOVAL SUCCESS RATE</p>
                            <DarkSkeleton count={1} width={'35%'} />
                        </div>
                    </CardBody>
                </Card>
            </Col>
        )})


    useEffect(() => {
        if (!data) return;
        const tmpServicesInfo = [];
        services.forEach((service) => {
            if (!Object.keys(serviceLogos).includes(service)) return;
            tmpServicesInfo.push({
                title: service,
                reported_links: data[service]["overall"]?.reported || 0,
                removal_rate: data[service]["overall"]?.removal_rate || 0,
                photo: serviceLogos[service],
                // isActive: activeServices.includes(serviceNamesMap[service]) || activeServices.includes(service) ? true : false
            })
        })
        setServicesInfo(tmpServicesInfo)
    }, [data,activeServices])
    return (
        <React.Fragment>
            <h4 className="mb-4">Platforms Insights</h4>
                <Row className="d-flex justify-content-center">
                    {
                    !data ? LoadingCards : (servicesInfo).map((item, key) => (
                        <Col lg={3} key={key} >
                            <Card >
                                <CardBody className="p-2 pt-4 pd-4" style={{ minHeight: "210px", width:'100%', marginRight: "5px" }} >
                                    <div className="text-center mb-3">
                                        <img src={item.photo} alt="" className="avatar-sm" />
                                        <h5 className="mt-4 mb-2 font-size-15">{item.title.toUpperCase()}</h5>
                                        <p className="mb-0"><b>{item.reported_links}</b> Reported Links</p>
                                        <p className="mb-0"><b>{item.removal_rate % 1 === 0 ? Math.round(item.removal_rate) : item.removal_rate}%</b> REMOVAL SUCCESS RATE</p>
                                        {/* {item.isActive && <Badge color="success" className="mt-3">Active Service</Badge>} */}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                </Row>
        </React.Fragment>
    );
}

export default ServicesData;