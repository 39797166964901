import React from 'react'
import { Card, CardBody, CardTitle } from 'reactstrap';
import MonthlyDetectedRemovedGraph from './helpers/MonthlyDetectedRemovedGraph';
import DarkSkeleton from './helpers/loading/DarkSkeleton';
function MonthlyDetectedRemoved({ data }) {
    if (!data) return null
    return (
        <Card>
            <CardBody>
                <CardTitle className="mb-4">Monthly Reported and Removed Links</CardTitle>
                {data ? <MonthlyDetectedRemovedGraph data={data} dataColors='["--bs-danger","--bs-success"]' /> : <DarkSkeleton height={300} />}
            </CardBody>
        </Card>
    )
}

export default MonthlyDetectedRemoved