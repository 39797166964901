import React, { useEffect } from "react";
import {
  Container,
  Row,
  Col
} from "reactstrap";


//i18n
import { withTranslation } from "react-i18next";

//redux
import { useDispatch } from "react-redux";
import { useQuery } from '@tanstack/react-query';
import fetchAPI from 'helpers/fetcher/fetchClient';

import { getUserId, getCustomerId } from 'helpers/auth/getUserId';
import { layoutModeTypes } from "../../constants/layout";
import { changeLayoutMode } from "store/actions";
import OverAllInsights from "./MainComponents/OverAllInsights";
import PerServiceGraph from "./MainComponents/PerServiceGraph";
import TopRemovals from "./MainComponents/TopRemovals";
import TopPiratedDomains from "./MainComponents/TopPiratedDomains";
import { TopCampaigns } from "./MainComponents/TopCampaigns";
import ServicesData from "./MainComponents/servicesData";
import MonthlyDetectedRemoved from "./MainComponents/MonthlyDetectedRemoved";
import WelcomeComp from "./MainComponents/WelcomeComp";


;

const Dashboard = props => {

  const dispatch = useDispatch();
  const [customerInfo, setCustomerInfo] = React.useState(localStorage.getItem("customerInfo") || {});


  
  const fetchCustomerInfo = async () => {
    const response = await fetchAPI.get(
      process.env.REACT_APP_CUSTOMERS_MANAGEMENT_URL +
      '/analytics/customer/details/?user_id=' + getUserId() + '&relations=media',
      process.env.REACT_APP_CAMPAIGNS_MANAGEMENT_TOKEN
    );
    return response;
  };
  const customerInfoQuery = useQuery({
    queryKey: ['customerInfo'],
    enabled: !!getUserId(),
    queryFn: fetchCustomerInfo,
    onSuccess: (data) => {
      setCustomerInfo(data);
      localStorage.setItem("customerId", data.id);
      localStorage.setItem("customerProfile", data?.media?.name || data?.profile);
      localStorage.setItem("customerName", data.customer_name);
    },
    onError: (error) => {
      setCustomerInfo({});
    }
  }
  );


  const DetectedRemovedInsightsQuery = useQuery({
    queryKey: ['detected-removed-insights'],
    enabled: !!getCustomerId(),
    queryFn: async () => {
      const data = await fetchAPI.get(
        process.env.REACT_APP_CUSTOMERS_MANAGEMENT_URL +
        '/analytics/customer/detected-removed/' + getCustomerId(),
        process.env.REACT_APP_CAMPAIGNS_MANAGEMENT_TOKEN
      );
      return data;
    }
  })

  const fetchAnalyticsSummary = async () => {
    const data = await fetchAPI.get(
      process.env.REACT_APP_CUSTOMERS_MANAGEMENT_URL +
      '/analytics/customer/overall/' + getCustomerId(),
      process.env.REACT_APP_CAMPAIGNS_MANAGEMENT_TOKEN
    );
    return data;
  };

  const OverAllInsightsQuery = useQuery(
    ['overall-insights'],
    fetchAnalyticsSummary,

  );

  const PerServiceStatisticQuery = useQuery({
    queryKey: ['per-service-statistics'],
    enabled: !!getCustomerId(),
    queryFn: async () => {
      const data = await fetchAPI.get(
        process.env.REACT_APP_CUSTOMERS_MANAGEMENT_URL +
        '/analytics/customer/current-year-per-service-statistics/' + getCustomerId(),
        process.env.REACT_APP_CAMPAIGNS_MANAGEMENT_TOKEN
      );
      return data;
    }
  })

  const TopRemovalsPerServiceQuery = useQuery({
    queryKey: ['top-removals-per-service'],
    enabled: !!getCustomerId(),
    queryFn: async () => {
      const data = await fetchAPI.get(
        process.env.REACT_APP_CUSTOMERS_MANAGEMENT_URL +
        '/analytics/customer/top-removal-per-service/' + getCustomerId(),
        process.env.REACT_APP_CAMPAIGNS_MANAGEMENT_TOKEN
      );
      return data;
    }
  })

  const DailyMonthlyRemovalsQuery = useQuery({
    queryKey: ['daily-monthly-removals'],
    enabled: !!getCustomerId(),
    queryFn: async () => {
      const data = await fetchAPI.get(
        process.env.REACT_APP_CUSTOMERS_MANAGEMENT_URL +
        '/analytics/customer/daily-monthly-statistics/' + getCustomerId(),
        process.env.REACT_APP_CAMPAIGNS_MANAGEMENT_TOKEN
      );
      return data;
    }
  })

  const TopPiratedDomainsQuery = useQuery({
    queryKey: ['top-pirated-domains'],
    enabled: !!getCustomerId(),
    queryFn: async () => {
      const data = await fetchAPI.get(
        process.env.REACT_APP_CUSTOMERS_MANAGEMENT_URL +
        '/analytics/customer/current-year-top-pirated-domains/' + getCustomerId(),
        process.env.REACT_APP_CAMPAIGNS_MANAGEMENT_TOKEN
      );
      return data;
    }
  });

  const TopCampaignsQuery = useQuery({
    queryKey: ['top-campaigns'],
    enabled: !!getCustomerId(),
    queryFn: async () => {
      const data = await fetchAPI.get(
        process.env.REACT_APP_CUSTOMERS_MANAGEMENT_URL +
        '/analytics/customer/top-campaigns/' + getCustomerId(),
        process.env.REACT_APP_CAMPAIGNS_MANAGEMENT_TOKEN
      );
      return data;
    }
  });

  const CountCampaignsQuery = useQuery({
    queryKey: ['allcampaigns'],
    enabled: !!getCustomerId(),
    queryFn: async () => {
      const data = await fetchAPI.get(
        process.env.REACT_APP_CAMPAIGNS_MANAGEMENT_URL +
        '/campaigns?page=*&fields=id,campaign_name,campaign_status&customer_id=' + getCustomerId() + '&relations=campaign_poster_photo_id,customer_campaign_services',
        process.env.REACT_APP_CAMPAIGNS_MANAGEMENT_TOKEN
      );
      return data;
    }
  });


  useEffect(() => {
    dispatch(changeLayoutMode(layoutModeTypes.DARK))
  }, [layoutModeTypes]);



  // console.log(DetectedRemovedInsightsQuery.data)
  //meta title
  document.title = "RH | Rightshero Analytics";


  return (
    <React.Fragment>
      <div className="page-content" style={{ backgroundColor: "#2a3042" }}>
        <Container fluid>
          <Row className='mb-5'>
            <Col md={5}>
              <WelcomeComp campaigns={CountCampaignsQuery.data?.results} />
            </Col>
            <Col md={7}>
              <h4 className="m-2 mb-3 text-muted">
                Overall Insights
              </h4>
              <OverAllInsights data={OverAllInsightsQuery.data} overall />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <PerServiceGraph data={DetectedRemovedInsightsQuery.data} socialMedia={false} />
            </Col>
          </Row>
          {/* <Row>
            <Col md={12}>
              <PerServiceGraph data={DetectedRemovedInsightsQuery.data} socialMedia={true} />
            </Col>
          </Row> */}
          <Row>
            <TopCampaigns topCampaignsData={TopCampaignsQuery.data} />
          </Row>
          <Row>
            <ServicesData data={PerServiceStatisticQuery.data} styleClass={"animated-card"} main={true} />
          </Row>
          <hr />
          <Row>
            <Col md={8} >
              <MonthlyDetectedRemoved data={DetectedRemovedInsightsQuery.data} />
            </Col>
            <Col md={4}>
              <TopRemovals todayRemovedLinks={DailyMonthlyRemovalsQuery.data?.today_removed} thisMonthRemovedLinks={DailyMonthlyRemovalsQuery.data?.this_month_removed} topRemovalServices={TopRemovalsPerServiceQuery.data} />
            </Col>
          </Row>
          <Row>
            <TopPiratedDomains topDomains={TopPiratedDomainsQuery.data} isLoading={TopPiratedDomainsQuery.isLoading} dataColors='["--bs-primary"]' />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


export default withTranslation()(Dashboard);
