import CryptoJS from 'crypto-js';

const currentDate = new Date().toISOString().split('T')[0];

const encryptText = (text) => {
    console.log(text);
    const ciphertext = CryptoJS.AES.encrypt(text, currentDate).toString();
    return btoa(ciphertext); // Encode to Base64;
};

const decryptText = (ciphertextBase64) => {
    try {
      const ciphertext = atob(ciphertextBase64); // Decode from Base64
      const bytes = CryptoJS.AES.decrypt(ciphertext, currentDate);
      const originalText = bytes.toString(CryptoJS.enc.Utf8);
      return originalText;
    } catch (error) {
      console.error('Decryption error:', error);
      return 'Decryption failed';
    }
  };
  

export { encryptText, decryptText };