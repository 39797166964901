import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, loginSuccess } from "./actions";

//Include Both Helper File with needed methods
import {
  postJwtLogin,
} from "../../../helpers/fakebackend_helper";

import {toast} from "react-toastify";


function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postJwtLogin, {
      email: user.email,
      password: user.password
    });
    if (response.data) {
      toast(response.message)
      yield put(loginSuccess(response));
      history('/dashboard');
    } else {
      return false;
    }
  } catch (error) {
    toast.error(error.message)
    console.log(error)
    yield put(apiError(error));
  }
}


function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");
    localStorage.clear();
    history('/login');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
