import getChartColorsArray from 'components/Common/ChartsDynamicColor';
import React, { useEffect } from 'react'
import ReactApexChart from 'react-apexcharts';

function MonthlyDetectedRemovedGraph({ data, dataColors }) {
    const spineareaChartColors = getChartColorsArray(dataColors);
    const [detectedSeries, setDetectedSeries] = React.useState([]);
    const [removedSeries, setRemovedSeries] = React.useState([]);
    const [dataCategories, setDataCategories] = React.useState([]);

    useEffect(() => {
        if (!data) return;
        let tmpCategories = [];
        let tmpDetectedSeries = [];
        let tmpRemovedSeries = [];
        Object.entries(data.overall).forEach(([key, value]) => {
            if (key === 'overall') return;
            tmpCategories.push(convertDatetoString(key));
            tmpDetectedSeries.push(value.reported);
            tmpRemovedSeries.push(value.removed);
        })
        setDataCategories(tmpCategories);
        setDetectedSeries(tmpDetectedSeries);
        setRemovedSeries(tmpRemovedSeries);

    }, [data])

    const series = [
        {
            name: "Reported Links",
            data: detectedSeries,
        },
        {
            name: "Removed Links",
            data: removedSeries,
        },
    ]

    const options = {
        chart: {
            toolbar: {
                show: false,
            },
        },
        dataLabels: {
            enabled: true,
        },
        stroke: {
            curve: "smooth",
            width: 3,
        },

        colors: spineareaChartColors,
        xaxis: {
            type: "date",
            categories: dataCategories,
            range: 11,
        },
        grid: {
            borderColor: "#f1f1f1",
        },
        tooltip: {
            x: {
                format: "dd/MM/yy HH:mm",
            },
        },
    }

    return (
        <ReactApexChart
            options={options}
            series={series}
            type="area"
            height="350"
        />
    )
}

export default MonthlyDetectedRemovedGraph

const convertDatetoString = (date) => {
    // the date is in the format of yyyy-mm
    // we need to convert it to MonthName yyyy
    const year = date.slice(0, 4);
    const month = date.slice(5, 7);
    let monthName = new Date(year, month - 1).toLocaleString('en-us', { month: 'long' });
    // shorten the month name
    monthName = monthName.slice(0, 3); // January -> Jan
    // shorten the year
    let yearShort = year.slice(2, 4); // 2021 -> 21
    return monthName + ' ' + yearShort;
};  