import React from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import avatar1 from "../../../assets/images/rh-profile-img.jpg"
import DarkSkeleton from "./helpers/loading/DarkSkeleton"


const WelcomeComp = ({ campaigns }) => {
  
  let totalCampaigns = <DarkSkeleton count={1} width={'50%'} />
  let totalActiveCampaigns = <DarkSkeleton count={1} width={'50%'} />
  if (campaigns) {
  totalCampaigns = campaigns.length
  totalActiveCampaigns = campaigns.filter(campaign => campaign.status === 'Active').length
}
  const customerData = {
    id: localStorage.getItem("customerId"),
    profile: localStorage.getItem("customerProfile"),
  }


  return (
    <React.Fragment>
      <Card className="overflow-hidden pt-2 pb-2 mb-0 my-card">
        <CardBody>
          <Row>
            <Col sm="4">
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%'
              }}>
                {!customerData.profile ? <DarkSkeleton width={'100px'} height={'100px'} borderRadius='50%' /> : <img
                  src={customerData.profile || avatar1}
                  alt=""
                  className="img-thumbnail rounded-circle"
                  style={{
                    width: '70%'
                  }}
                />}
              </div>
            </Col>

            <Col sm="8">
              <div className="pt-4">
                <Row>
                  <Col xs="6">
                    <h3 className="font-size-20" style={{ marginLeft: "15px" }}>{totalActiveCampaigns}</h3>
                    <br></br>
                    <h2 className="text-muted mb-0" style={{ fontSize: "12px", marginLeft: "15px" }}>Active Campaigns</h2>
                  </Col>
                  <Col xs="6">
                    <h3 className="font-size-20" style={{ marginLeft: "15px" }}>{totalCampaigns}</h3>
                    <br></br>
                    <h2 className="text-muted mb-0" style={{ fontSize: "12px", marginLeft: "15px" }}>All Campaigns</h2>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp
