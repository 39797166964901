import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed } from "./actions"

// axios clients
import {
  postRegister,
  postJwtRegister
} from "../../../helpers/fakebackend_helper"


// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user } }) {
  try {
    const response = yield call(postJwtRegister, 'users/signup/', user)
    console.log('logging the res from the saga file')
    console.log(response)
    const mockRes = {
                  "status": 201,
                  "message": "User registered successfully!",
                  "data": {
                    "id": 2,
                    "email": "mosobhy266@gmail.com",
                    "username": "mohamed",
                    "first_name": "",
                    "last_name": "",
                    "is_superuser": false,
                    "is_staff": false,
                    "is_active": true,
                    "date_joined": "2023-03-07T16:19:03.297096Z",
                    "last_login": null,
                    "tier": {
                      "id": 1,
                      "name": "Free",
                      "num_of_jobs": 1,
                      "max_video_length": 1,
                      "max_char_length": 1000,
                      "created_at": "2023-03-07T15:01:07.420874Z",
                      "updated_at": "2023-03-07T15:01:07.420874Z"
                    },
                    "is_verified": false
                  }
                }
    yield put(registerUserSuccessful(mockRes))
  } catch (error) {
    console.log("There was an error registering: ", error)
    yield put(registerUserFailed(error))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
