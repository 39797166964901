import CampaignDetail from "../Dashboard/Campaign/campaignDetail";
import {
    Col,
    Row,
    Container,
} from "reactstrap";
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query';
import React, { useState } from "react";
import OverAllInsights from "./MainComponents/OverAllInsights";
import MonthlyDetectedRemoved from "./MainComponents/MonthlyDetectedRemoved";
import TopRemovals from "./MainComponents/TopRemovals";
import ServicesData from "./MainComponents/servicesData";
import { ANALYTICS_APIS } from "../../apis/apis";
import { CAMPAIGN_APIS } from "../../apis/apis";

const CampaignDashboard = props => {
    let { campaign_id } = useParams()
    const [numOfDays, setNumOfDays] = useState(7);

    const campaignInfoQuery = useQuery({
        queryKey: ['campaignInfo', campaign_id],
        queryFn: async () => await CAMPAIGN_APIS.retrieve(campaign_id, {
            expand: 'customer,campaign_type,platforms,country',
            fields: [
                'id',
                'campaign_name',
                'campaign_status',
                'sample_of_work',
                'campaign_poster_path',
                'customer.customer_name',
                'customer.customer_logo_image_thumbnail',
                'campaign_type.type',
                'platforms.platform_name',
                'country.country_name',
            ].join(',')
        }),
    });

    const CampaignOverallInsightsQuery = useQuery({
        queryKey: ['CampaignOverallInsights', campaign_id],
        queryFn: async () => await ANALYTICS_APIS.detectedReportedRemoved({ campaign_id: campaign_id }),
    });

    const DetectedRemovedInsightsQuery = useQuery({
        queryKey: ['CampaignServicesInsights', campaign_id],
        queryFn: async () => await ANALYTICS_APIS.perPlatformMonthDetectedReportedRemoved({ campaign_id: campaign_id }),
    });

    const TopRemovalsPerServiceQuery = useQuery({
        queryKey: ['TopRemovalsPerService', campaign_id],
        queryFn: async () => await ANALYTICS_APIS.topRemovals({ campaign_id: campaign_id }),
    });

    const DailyReportedRemovedQuery = useQuery({
        queryKey: ['daily-reported-removed', numOfDays, campaign_id],
        queryFn: async () => await ANALYTICS_APIS.dailyDetectedReportedRemoved({
            num_of_days: numOfDays,
            campaign_id: campaign_id
        })
    })

    //meta title
    document.title = "RH | Rightshero Analytics"


    return (
        <React.Fragment>
            <div className="page-content" style={{ backgroundColor: "#2a3042" }}>

                <Container fluid>
                    <CampaignDetail campaignData={campaignInfoQuery.data} />
                    <Row className="mt-3">
                        <h5 className="mb-3">Overall Insights For The Current Year</h5>
                        <OverAllInsights data={CampaignOverallInsightsQuery.data} />
                    </Row>
                    <Row className="mt-3">
                        <ServicesData data={DetectedRemovedInsightsQuery.data} styleClass={"animated-card"} />
                    </Row>
                    <Row className="mt-3">
                        <Row>
                            <Col md={8} >
                                <MonthlyDetectedRemoved data={DailyReportedRemovedQuery.data} numOfDays={numOfDays} setNumOfDays={setNumOfDays} />
                            </Col>
                            <Col md={4}>
                                <TopRemovals todayRemovedLinks={TopRemovalsPerServiceQuery.data?.today_removals} thisMonthRemovedLinks={TopRemovalsPerServiceQuery.data?.this_month_removals} topRemovalServices={TopRemovalsPerServiceQuery.data?.top_removals} />
                            </Col>
                        </Row>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CampaignDashboard
