import axios from "axios";
import { toast } from "react-toastify";

export const baseURL = process.env.REACT_APP_API_BASE_URL;

const instance = axios.create({
    baseURL,
    headers: {
        Accept: "application/json",
        Authorization: localStorage.getItem("social_access_token")
            ? "Bearer " + localStorage.getItem("social_access_token")
            : null,
    },
});


instance.interceptors.request.use((config) => {
    const accessToken = localStorage.getItem("social_access_token")
        ? "Bearer " + localStorage.getItem("social_access_token")
        : null;
        config.headers["Authorization"] = accessToken;
    return config;
});

function displayErrors(errors, parentKey = '', error_data = []) {
    if (typeof errors === 'string' || typeof errors === 'number') {
        error_data.push(`${parentKey}: ${errors}`);
    } else if (typeof errors === 'object' && errors !== null) {
        for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
                const nextKey = parentKey ? `${parentKey}.${key}` : key;
                displayErrors(errors[key], nextKey, error_data);
            }
        }
    }
}

instance.interceptors.response.use(
    (response) => {
        return response.data;
    },
    async (error) => {
        //Bearer token logic
        const originalRequest = error.config;

        if (
            error.response.status === 401 &&
            originalRequest.url === "users/token/refresh/"
        ) {
            return Promise.reject(error);
        }
        if (
            error.response.data.code === "token_not_valid" &&
            error.response.status === 401
        ) {
            const refreshToken = localStorage.getItem("social_refresh_token");

            if (refreshToken) {
                return instance
                    .post("users/token/refresh/", { refresh: refreshToken })
                    .then((response) => {
                        localStorage.setItem("social_access_token", response.data.access);
                        instance.defaults.headers["Authorization"] =
                            "Bearer " + response.data.access;
                        originalRequest.headers["Authorization"] =
                            "Bearer " + response.data.access;
                        return instance(originalRequest);
                    })
                    .catch((err) => { 
                        localStorage.removeItem("social_access_token");
                        localStorage.removeItem("social_refresh_token");
                        localStorage.removeItem("authUser");
                        window.location.href = "/login";
                    });
            }
            else {
                localStorage.removeItem("social_access_token");
                localStorage.removeItem("social_refresh_token");
                localStorage.removeItem("authUser");
                window.location.href = "/login";
            }
        }
        if (error.response.status === 400) {
            let error_data = []
            console.log(error.response.data);
            displayErrors(error.response.data, '', error_data);
            return Promise.reject(error_data.join('\n'));
        }
        if (error.response.status === 403) {
            return Promise.reject(`You don't have permission to access this resource`);
        }

        if (error.response.status === 404) {
            return Promise.reject(`The resource you are trying to access does not exist`);
        }

        if (error.response.status >= 500) {
            return Promise.reject(`Internal server error`);
        }

        return Promise.reject("Something went wrong. Please try again later.");
    }
);

export default instance;