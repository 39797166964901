import { useQuery } from '@tanstack/react-query';
import fetchAPI from 'helpers/fetcher/fetchClient';
import React, { useRef } from 'react'
import Select from 'react-select';
import { Label } from 'reactstrap';

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function ServicesDropDown({ onChange, onBlur, value, validation, isCampaign, filter = [], isAll = false , exclude = []}) {

    let servicesQuery = useQuery({
        queryKey: ["services-dropdown"],
        queryFn: () => fetchAPI.get(process.env.REACT_APP_SERVICES_MANAGEMENT_URL + '/services/?page=*&fields=id,service_name', process.env.REACT_APP_SERVICES_MANAGEMENT_TOKEN)
    })

    if (!servicesQuery.isLoading) {
        servicesQuery = servicesQuery.data.results.reduce((accumulator, item) => {
            if (isCampaign && (item.service_name === "live-streaming" || item.service_name === "brand-protection" || item.service_name === "app-store")) {
                // continue;
                return accumulator;
            } else {
                const newItem = {
                    label: capitalizeFirstLetter(item.service_name),
                    value: item.id
                };
                if (exclude.length > 0) {
                    if (!exclude.includes(item.service_name.toLowerCase())) {
                        accumulator.push(newItem);
                    }
                }
                else {
                    accumulator.push(newItem);
                }
                return accumulator;
            }
        }, []);

        if (value != "") {
            value = servicesQuery.find(option =>
                option.value === value)
        }
        if (filter.length > 0) {
            servicesQuery = servicesQuery.filter(option => {
                return filter.includes(option.label)
            })
        }
        if (isAll) {
            servicesQuery.unshift({
                label: "All",
                value: 10000
            })
        }
    }
    const customStyles = {
        option: (base) => {
            return {
                ...base,
                backgroundColor: "white",
            };
        }
    };

    return (
        <div>
            <Label htmlFor="service">Service</Label>
            <Select
                onChange={onChange}
                className={`basic-multi-select ${validation.touched.service && validation.errors.service ? "invalid" : ""}`}
                classNamePrefix="select"
                onBlur={onBlur}
                value={value}
                styles={customStyles}
                isLoading={servicesQuery.isLoading}
                aria-invalid={
                    validation.touched.service && validation.errors.service ? true : false
                }
                options={servicesQuery} />
            {validation.touched.service && validation.errors.service ? (
                <div className="error-message">{validation.errors.service}</div>
            ) : null}
        </div>
    )
}

export default ServicesDropDown