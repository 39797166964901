import React from 'react'
import DarkSkeleton from './DarkSkeleton'

function CampaignLoadingCard() {
  return (
    <div className='mb-5'>
        <DarkSkeleton width={'220px'} height={'250px'} />
        <DarkSkeleton count={3} />
    </div>
  )
}

export default CampaignLoadingCard