import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

const DarkSkeleton = (props) => {

    return (
        <div style={{
            display: 'block',
            width: '100%',
            height: '100%',
        }}>
            <SkeletonTheme  baseColor="rgba(42,48,66,0.13)" highlightColor="rgba(255,255,255,0.13)">
                <Skeleton  style={{
                    borderRadius: props.borderRadius || '0px',
                }} {...props} />
            </SkeletonTheme>
        </div>
    );
};

export default DarkSkeleton;
