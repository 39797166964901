import instance from "./conf";


const PLATFORM_APIS = {
    list: async (params) => await instance.get("platforms/", { params: { ...params } }),
    create: async (data) => await instance.post("platforms/", data),
    update: async (data, id) => await instance.patch(`platforms/${id}/`, data),
};


const ANALYTICS_APIS = {
    numberOfCampaigns: async (params) => await instance.get("analytics/number-of-campaigns/", { params: { ...params }}),
    detectedReportedRemoved: async (params) => await instance.get("analytics/detected-reported-removed/", { params: { ...params }}),
    perCustomerDetectedReportedRemoved: async (params) => await instance.get("analytics/per-customer-detected-reported-removed/", { params: { ...params }}),
    perPlatformMonthDetectedReportedRemoved: async (params) => await instance.get("analytics/per-platform-month-detected-reported-removed/", { params: { ...params }}),
    topCampaignsDetectedReportedRemoved: async (params) => await instance.get("analytics/top-campaigns-detected-reported-removed/", { params: { ...params }}),
    dailyDetectedReportedRemoved: async (params) => await instance.get("analytics/daily-detected-reported-removed/", { params: { ...params }}),
    topRemovals: async (params) => await instance.get("analytics/top-removals/", { params: { ...params }}),
    opsPerformance: async (params) => await instance.get("analytics/ops-performance/", { params: { ...params }}),
};

const CAMPAIGN_APIS = {
    list: async (params) => await instance.get("campaigns/", { params: { ...params } }),
    retrieve: async (id, params) => await instance.get(`campaigns/${id}/`, { params: { ...params } }),
    create: async (data) => await instance.post("campaigns/", data),
    update: async (data, id) => await instance.patch(`campaigns/${id}/`, data),
    getLOA: async (params) => await instance.get(`campaigns/loa/`, { params: { ...params } }),
    updateLOA: async (data) => await instance.patch(`campaigns/loa/`, data),
};


const VIDEO_APIS = {
    list: async (params) => await instance.get("videos/", { params: { ...params } }),
    create: async (data) => await instance.post("videos/", data),
    update: async (data, id) => await instance.patch(`videos/${id}/`, data),
};

export {
    PLATFORM_APIS,
    ANALYTICS_APIS,
    CAMPAIGN_APIS,
    VIDEO_APIS
};