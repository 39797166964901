import Login from "pages/Authentication/Login";
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Dashboard from "../pages/Dashboard/dashboardV2"
import Pages404 from "pages/Utility/pages-404";
import UserManagementRoutes from "./modules/UserManagementRoutes";
import CampaignsModal from "pages/CampaignAnalytics/CampaignsModal";
import SocialMediaAnalytics from "pages/CampaignAnalytics/SocialMediaAnalytics";
import CampaignAnalytics from "pages/Dashboard/Campaign/index";
import GenerateReport from "pages/GenerateReport/GenerateReport";
import TicketsCreate from "pages/Dashboard/Tickets/TicketsCreate";
import SocialMediaDashboard from "pages/Dashboard/SocialMediaDashboard";
import CampaignDashboard from "pages/CampaignAnalytics/CampaignDashboard";
import GenerateSocialMediaReport from "pages/GenerateReport/GenerateSocialMediaReport";

const authProtectedRoutes = [
  
  {
    path: "/dashboard",
    exact: true,
    component: <Dashboard />,
  },
  {
    path: "social-media-dashboard",
    exact:true,
    component: <SocialMediaDashboard/>
  },
  ...UserManagementRoutes,
  {
    path: "/general-analytics",
    exact: true,
    component: <CampaignsModal />,
  },
  {
    path: "/social-media-analytics",
    exact: true,
    component: <SocialMediaAnalytics />,
  },
  {
    path: "/campaigns/:campaign_id",
    exact: true,
    component: <CampaignAnalytics />,
  },
  {
    path: "/campaigns-analytics/:campaign_id",
    exact: true,
    component: <CampaignDashboard />,
  },
  {
    path: "/generate-general-reports",
    exact: true,
    component: <GenerateReport />,
  },
  {
    path: "/generate-social-reports",
    exact: true,
    component: <GenerateSocialMediaReport />,
  },
  {
    path: "/",
    exact: true,
    component: < Navigate to="/dashboard" />,
  },
  {
    path: "/tickets",
    exact: true,
    component: <TicketsCreate />,
  },
  {
    path: "*",
    exact: true,
    component: < Navigate to="/404" />,
  },
];

const publicRoutes = [
  { path: "/404", component: <Pages404 /> },

  // { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  // { path: "/forgot-password", component: <ForgetPwd /> },
  // { path: "/register", component: <Register /> },
];

export { authProtectedRoutes, publicRoutes };
