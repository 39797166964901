import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

// Import Editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { getCustomerId, getCustomerName, getUserId } from "helpers/auth/getUserId";
import { set } from "lodash";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from '@tanstack/react-query';
import fetchAPI from 'helpers/fetcher/fetchClient';
import FilesSection from "./components/FilesSection";



const TicketsCreate = () => {
  // Meta title
  document.title = "Submit a request | Rightshero";

  const [ticketSubject, setTicketSubject] = useState("");
  const [ticketDescription, setTicketDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [mediaLoading, setMediaLoading] = useState(false);
  const [ticketsData, setTicketsData] = useState([]);
  const [openedTicketId, setOpenedTicketId] = useState(null);
  const [ticketMedia, setTicketMedia] = useState([]);
  const [modal, setModal] = useState(false);
  const [mediaModal, setMediaModal] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [files, setFiles] = useState([])

  const toggleModal = (ticket) => {
    setSelectedTicket(ticket);
    setModal(!modal);
  };

  const toggleMediaModal = () => {
    setMediaModal(!mediaModal);
  };

  const userId = getUserId();
  const customerId = getCustomerId();
  const lastUpdatedById = getUserId();
  const customerName = getCustomerName();

  // Helper function to format date without time zone
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString(); // Adjust formatting as needed
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Open":
        return "text-danger"; // Set to red
      case "In Progress":
        return "text-primary"; // Set to green
      case "Closed":
        return "text-success"; // Set to green
      default:
        return "";
    }
  };

  const handleFilePick = (e) => {
    console.log(e.target.files)
    console.log("inside handle file pick");
    console.log(files);
    const newFiles = Object.entries(e.target.files).map(([key, value]) => {
      if (key !== 'length') {
        const fileExtension = value.name.split('.').pop()
        if (fileExtension === 'exe' || fileExtension === 'sh') {
          toast.error(".exe and .sh files are not allowed")
        }
        else {
          return value
        }

      }
    }).filter((file) => {
      return file !== undefined
    })

    setFiles([...files, ...newFiles])
  }

  const createTicketMedia = useMutation({
    mutationFn: async (formData) => {
      try {
        console.log("here");
        console.log(formData);

        const ticketId = formData.get("ticket_id"); // Get ticket ID directly

        const response = await fetchAPI.uploadFile(
          process.env.REACT_APP_CUSTOMERS_MANAGEMENT_URL +
          `/customerActions/tickets/${ticketId}/media-files/`,
          formData,
          process.env.REACT_APP_CUSTOMERS_MANAGEMENT_URL
        );
        // check response is 201
        console.log(response);
        // // if response is an object with status 201
        // if (typeof response === 'object' && response !== null) {
        //   toast.success("Files Uploaded Successfully");
        // }
        // else {
        //   throw new Error("Failed to upload media");
        // }

        return response;
      } catch (error) {
        throw {
          message: error.message,
        };
      }
    },
    onSuccess: () => {
      setFiles([]);
      toast.success("Files Uploaded Successfully");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });



  const handleSubmit = async () => {
    // Basic form validation
    if (!ticketSubject.trim() || !ticketDescription.trim()) {
      toast.error("Please fill out both subject and description fields.");
      return;
    }

    // Construct the data object for the POST request
    const data = {
      subject: ticketSubject,
      description: ticketDescription,
      customer: customerId,
      customer_name: customerName,
      status: "Open",
      feedback: null,
      last_updated_by: userId
    };
    console.log(data);

    try {
      setLoading(true);

      // Make the POST request
      const response = await fetch(process.env.REACT_APP_CUSTOMERS_MANAGEMENT_URL + `/customerActions/customers/${customerId}/tickets/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        toast.success("Ticket submitted successfully!");
        // get response data
        const responseData = await response.json();
        // get ticket id
        const ticketId = responseData.id;
        console.log(ticketId);
        if (files.length > 0) {
          console.log("inside if >0");
          // Loop through files and create media for each
          for (const file of files) {
            let formData = new FormData();
            formData.append("ticket_id", ticketId);
            formData.append("media", file, file.name);
            console.log(formData + "formdata");
            // Run media request
            await createTicketMedia.mutate(formData);
          }
        }
        // refresh the page
        //  window.location.reload();
        // reset all fields
        setTicketSubject("");
        setTicketDescription("");
        //setFiles([]);

      } else {
        toast.error("Failed to submit ticket. Please try again.");
      }
    } catch (error) {
      toast.error("Failed to submit ticket. Please try again.");
    } finally {
      setLoading(false);
    }

    //   // Handle the response as needed
    //   if (response.ok) {
    //     toast.success("Ticket submitted successfully!");
    //     // get response data
    //     const responseData = await response.json();
    //     // get ticket id
    //     const ticketId = responseData.id;
    //     console.log(ticketId);
    //     if (files.length > 0) {
    //       console.log("inside if >0");
    //         files.forEach((file) => {
    //             let formData = new FormData()
    //             formData.append("ticket_id", ticketId)
    //             formData.append("media", file, file.name)
    //             console.log(formData+"formdata");
    //             createTicketMedia.mutate({formData});
    //         })
    //     }
    //     // refresh the page
    //   //  window.location.reload();
    //   } else {
    //     toast.error("Failed to submit ticket. Please try again.");
    //   }
    // } catch (error) {
    //   toast.error("Failed to submit ticket. Please try again.");
    // } finally {
    //   setLoading(false);
    // }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_CUSTOMERS_MANAGEMENT_URL +
          `/customerActions/customers/${customerId}/tickets`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        const tickets = await response.json();
        setTicketsData(tickets);

      } catch (error) {
        console.error("Error fetching ticket data");
      }
    };

    fetchData(); // Call the function when the component mounts
  }, []); // Empty dependency array means it runs once when component mounts
  // /api/customerActions/tickets/40/media-files/?relations=media_data


  useEffect(() => {
    const fetchData = async () => {
      setMediaLoading(true);
      try {
        const response = await fetch(
          process.env.REACT_APP_CUSTOMERS_MANAGEMENT_URL +
          `/customerActions/tickets/${openedTicketId}/media-files/?relations=media_data`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        let media = await response.json();
        media = media.results.map((media) => {
          return {
            url: media.media_data.url,
          }
        })
        setTicketMedia(media);
        setMediaLoading(false);

      } catch (error) {
        console.error("Error fetching ticket data");
        setMediaLoading(false);
      }
    };

    fetchData(); // Call the function when the component mounts
  }, [openedTicketId]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Create New Ticket</CardTitle>
                  <form>
                    <FormGroup className="mb-4" row>
                      <Label htmlFor="ticketSubject" className="col-form-label col-lg-2">
                        Request Subject
                      </Label>
                      <Col lg="10">
                        <Input
                          id="ticketSubject"
                          name="ticketSubject"
                          type="text"
                          className="form-control"
                          placeholder="Enter Ticket Subject..."
                          value={ticketSubject}
                          onChange={(e) => setTicketSubject(e.target.value)}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label className="col-form-label col-lg-2">Request Description</Label>
                      <Col lg="10">
                        <Input
                          type="textarea"
                          style={{ height: '200px' }}
                          placeholder="Place Your Content Here..."
                          value={ticketDescription}
                          onChange={(e) => setTicketDescription(e.target.value)}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>

                      <Label htmlFor="files" className="col-form-label col-lg-2">Attach Files</Label>
                      <Col lg="10">
                        <Input
                          type="file"
                          id='files'
                          onChange={handleFilePick}
                          value=""
                          accept="*/*,!.exe,!.sh"
                          multiple
                        >
                        </Input>
                        {/* display files and enable delete feature */}
                        {files && files.map((file, index) => {
                          return <div key={index} style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center"
                          }}>
                            <div className='m-3'>{file.name}</div>

                            <div onClick={() => {
                              const newFiles = [...files]
                              newFiles.splice(index, 1)
                              setFiles(newFiles)
                            }}
                              style={{ cursor: "pointer" }}>
                              <i className="fa-solid fa-xmark fa-lg"></i>
                            </div>
                          </div>
                        })}

                      </Col>


                    </FormGroup>

                    <Row className="justify-content-end">
                      <Col lg="10">
                        <Button
                          type="button"
                          color="primary"
                          onClick={handleSubmit}
                          disabled={loading}
                        >
                          {loading ? "Submitting..." : "Submit Request"}
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* Add a table to display ticketsData */}
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Ticket Data</CardTitle>
                  {ticketsData.results ? (
                    <Table striped bordered>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Subject</th>
                          <th>Ticket Date</th>
                          <th>Last Updated At</th>
                          <th>Status</th>
                          <th>Info</th>
                          <th>attachment</th>
                          {/* Add more headers based on your data structure */}
                        </tr>
                      </thead>
                      <tbody>
                        {ticketsData.results.map((ticket) => (
                          <tr key={ticket.id}>
                            <td>{ticket.id}</td>
                            <td>{ticket.subject}</td>
                            <td>{formatDate(ticket.created_at)}</td>
                            <td>{formatDate(ticket.updated_at)}</td>
                            <td className={getStatusColor(ticket.status)}>{ticket.status}</td>
                            <td>
                              <Button color="info" onClick={() => toggleModal(ticket)}>
                                More Info
                              </Button>
                            </td>
                            <td>
                              <Button color="info" onClick={async () => {
                                setOpenedTicketId(ticket.id)
                                toggleMediaModal()
                              }}>
                                attachment
                              </Button>
                            </td>

                            {/* Add more cells based on your data structure */}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <p>No ticket data available.</p>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Modal for displaying more information */}
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>More Information</ModalHeader>
        <ModalBody>
          {selectedTicket && (
            <div>
              <h5>Subject: {selectedTicket.subject ?? ""}</h5>
              <p><strong>Description: </strong>{selectedTicket.description ?? ""}</p>
              <p><strong>Feedback: </strong> {selectedTicket.feedback ?? ""}</p>
              {/* Add more information as needed */}
            </div>
          )}
        </ModalBody>
      </Modal>
      <Modal isOpen={mediaModal} toggle={toggleMediaModal}>
        <ModalHeader toggle={toggleMediaModal}>Attachments</ModalHeader>
        <ModalBody>
          {ticketMedia && !mediaLoading && (
            <FilesSection files={ticketMedia} />
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default TicketsCreate;
