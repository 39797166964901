import React from 'react'
import { Label } from 'reactstrap'
import Select from 'react-select'
import { useQuery } from '@tanstack/react-query'
import { PLATFORM_APIS } from 'apis/apis'


function PlatformsDropdown({ onChange, onBlur, value, validation, translate, disabled = false, label=true }) {
    let options = []

    const PlatformsQuery = useQuery({
        queryKey: ['dropdown','platforms'],
        queryFn: async () => {
            return await PLATFORM_APIS.list({
                page: '*',
                ordering: 'platform_name',
                fields: 'id,platform_name'
            })
        }
    })

    if (PlatformsQuery?.data?.results) {
        options = PlatformsQuery.data.results.map((item) => {
            return {
                label: item.platform_name,
                value: item.id
            }
        })
    }

    if (value !== "" && value !== null && value !== undefined) {
        value = options.filter(option =>
            option.value === value)
    }

    const customStyles = {
        option: (base) => {
            return {
                ...base,
                backgroundColor: "white",
            };
        }
    };
    return (
        <div>
            {label && <Label htmlFor="platform">{translate("Platform")}</Label>}
            <Select
                cacheOptions
                defaultOptions
                onChange={onChange}
                className={`basic-multi-select ${validation?.touched?.platform && validation?.errors?.platform ? "invalid" : ""}`}
                classNamePrefix="select"
                onBlur={onBlur}
                value={value}
                styles={customStyles}
                aria-invalid={
                    validation?.touched?.platform && validation?.errors?.platform ? true : false
                }
                options={options}
                disabled={disabled}
            />
            {validation?.touched?.platform && validation?.errors?.platform ? (
                <div className="error-message">{validation?.errors?.platform}</div>
            ) : null}
        </div>
    )
}

export default PlatformsDropdown