import React from "react";
import {
  Container,
  Row,
  Col
} from "reactstrap";

import { withTranslation } from "react-i18next";

import { useQuery } from '@tanstack/react-query';
import OverAllInsights from "../CampaignAnalytics/MainComponents/OverAllInsights";
import PerServiceGraph from "../CampaignAnalytics/MainComponents/PerServiceGraph";
import TopRemovals from "../CampaignAnalytics/MainComponents/TopRemovals";
import TopPiratedDomains from "../CampaignAnalytics/MainComponents/TopPiratedDomains";
import { TopCampaigns } from "../CampaignAnalytics/MainComponents/TopCampaigns";
import ServicesData from "../CampaignAnalytics/MainComponents/servicesData";
import MonthlyDetectedRemoved from "../CampaignAnalytics/MainComponents/MonthlyDetectedRemoved";
import WelcomeComp from "../CampaignAnalytics/MainComponents/WelcomeComp";
import PerCustomerStats from "../CampaignAnalytics/MainComponents/PerCustomerStats";
import { ANALYTICS_APIS, PLATFORM_APIS } from "../../apis/apis.js"

const Dashboard = props => {
  const [numOfDays, setNumOfDays] = React.useState(7);

  const DetectedRemovedInsightsQuery = useQuery({
    queryKey: ['per-service-detected-removed'],
    queryFn: async () => await ANALYTICS_APIS.perPlatformMonthDetectedReportedRemoved(),
  })

  const OverAllInsightsQuery = useQuery({
    queryKey: ['overall-insights'],
    queryFn: async () => await ANALYTICS_APIS.detectedReportedRemoved(),
  });

  const TopRemovalsPerServiceQuery = useQuery({
    queryKey: ['top-removals-per-service'],
    queryFn: async () => await ANALYTICS_APIS.topRemovals(),
  })


  const TopCampaignsQuery = useQuery({
    queryKey: ['top-campaigns'],
    queryFn: async () => await ANALYTICS_APIS.topCampaignsDetectedReportedRemoved(),
  });

  const CountCampaignsQuery = useQuery({
    queryKey: ['countCampaigns'],
    queryFn: async () => await ANALYTICS_APIS.numberOfCampaigns(),
  });

  const PerCustomerReportedRemovedQuery = useQuery({
    queryKey: ['reported-removed-stats-per-customer'],
    queryFn: async () => await ANALYTICS_APIS.perCustomerDetectedReportedRemoved(),
  })

  const DailyReportedRemovedQuery = useQuery({
    queryKey: ['daily-reported-removed', numOfDays],
    queryFn: async () => await ANALYTICS_APIS.dailyDetectedReportedRemoved({
      num_of_days: numOfDays
    })
  })


  // const ActiveServices = useQuery({
  //   queryKey: ['ActiveServices'],
  //   queryFn: async () => await PLATFORM_APIS.list({
  //     page: '*',
  //     platform_status: true,
  //     fields: 'id,platform_name',
  //   }),
  // })





  //meta title
  document.title = "RH | Rightshero Analytics";
  // console.log(TopCampaignsQuery?.data)

  return (
    <React.Fragment>
      <div className="page-content" style={{ backgroundColor: "#2a3042" }}>

        <Container fluid >
          <Row className='mb-3'>
            <Col md={5}>
              <WelcomeComp campaigns={CountCampaignsQuery?.data} />
            </Col>
            <Col md={7}>
              {/**Row for stats summary */}
              <h4 className="m-2 mb-3 text-muted">
                Overall Insights
              </h4>
              <OverAllInsights data={OverAllInsightsQuery?.data} overall />
              {/**Row for barchart */}
            </Col>
          </Row>
          {/* <Row>
            <Col md={12}>
              <PerCustomerStats data={PerCustomerReportedRemovedQuery?.data} />
            </Col>
          </Row> */}
          <Row>
            <Col md={12}>
              <PerServiceGraph data={DetectedRemovedInsightsQuery?.data} socialMedia={false} />
            </Col>
          </Row>
          <Row>
            <TopCampaigns topCampaignsData={TopCampaignsQuery?.data} />
          </Row>
          <hr />
          <Row>
            <Col md={8} >
              <MonthlyDetectedRemoved data={DailyReportedRemovedQuery?.data} numOfDays={numOfDays} setNumOfDays={setNumOfDays} />
            </Col>
            <Col md={4}>
              <TopRemovals todayRemovedLinks={TopRemovalsPerServiceQuery?.data?.today_removals} thisMonthRemovedLinks={TopRemovalsPerServiceQuery.data?.this_month_removals} topRemovalServices={TopRemovalsPerServiceQuery.data?.top_removals} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


export default withTranslation()(Dashboard);