import React, { useEffect } from 'react';
import { Badge, Card, CardBody, Col, Row } from 'reactstrap';
import '../ServicesData.css';
import google_logo from '../../../assets/images/services/google_logo.png';
import youtube_logo from '../../../assets/images/services/youtube_logo.png';
import okru_logo from '../../../assets/images/services/okru_logo.png';
import bing_logo from '../../../assets/images/services/bing_logo.png';
import dailymotion_logo from '../../../assets/images/services/dailymotion_logo.png';
import vimeo_logo from '../../../assets/images/services/vimeo_logo.png';
import cyberlocker_logo from '../../../assets/images/services/cyberlocker_logo.png';
import telegram_logo from '../../../assets/images/services/telegram_logo.png';
import facebook_logo from '../../../assets/images/services/facebook_logo.png';
import tiktok_logo from '../../../assets/images/services/tiktok_logo.png';
import instagram_logo from '../../../assets/images/services/instagram_logo.png';
import twitter_logo from '../../../assets/images/services/twitter_logo.png';
import app_store_logo from '../../../assets/images/services/app-stores.webp';
import Loading from '../../../components/Common/loading/Loading';
import DarkSkeleton from './helpers/loading/DarkSkeleton';
import { useQuery } from '@tanstack/react-query';
import fetchAPI from 'helpers/fetcher/fetchClient';
import { getCustomerId } from 'helpers/auth/getUserId';

const ServicesData = ({ data, main=false }) => {

    const [servicesInfo, setServicesInfo] = React.useState([]);
    const [activeServices, setActiveServices] = React.useState([]);
    const [appsData, setAppsData] = React.useState({});
    if (main) {
        const appsQuery = useQuery({
            queryKey: ['appsData', getCustomerId()],
            queryFn: async () => {
                const response = await fetchAPI.get(
                    process.env.REACT_APP_CUSTOMERS_MANAGEMENT_URL +
                    '/analytics/customer/apps-statistics/' + getCustomerId(),
                    process.env.REACT_APP_CAMPAIGNS_MANAGEMENT_TOKEN
                );
                return response;
            },
            onSuccess: (data) => {
                setAppsData(data);
            }
        })
    }

    const serviceLogos = {
        google: google_logo,
        bing: bing_logo,
        cyberlocker: cyberlocker_logo,
        okru: okru_logo,
        dailymotion: dailymotion_logo,
        vimeo: vimeo_logo,
        telegram: telegram_logo,
        tiktok: tiktok_logo,
        youtube: youtube_logo,
        facebook: facebook_logo,
        instagram: instagram_logo,
        twitter: twitter_logo,
        apps: app_store_logo,
    };    
    const serviceNamesMap = {
        'okru': 'ok.ru',
        'cyberlocker': 'ugc-source',
        'facebook': 'Facebook' ,
        'instagram': 'Instagram',
        'twitter':'Twitter',
        'youtube': 'YouTube',
        'tiktok': 'Tiktok',
    }

    const services = Object.keys(serviceLogos);

    const LoadingCards = []
    Object.entries(serviceLogos).forEach(([key, value]) => {
        if (Object.keys(serviceNamesMap).includes(key) && key !== 'cyberlocker' && key !== 'okru') return;
        if (key === 'apps' && main === false) return;
        LoadingCards.push(
            <Col lg={3} key={key}>
                <Card >
                    <CardBody className="p-2 pt-4 pd-4" style={{ minHeight: "210px", width:'100%', marginRight: "5px" }} >
                        <div className="text-center mb-3">
                            <img src={value} alt="" className="avatar-sm" />
                            <h5 className="mt-4 mb-2 font-size-15">{key.toUpperCase()}</h5>
                            <p className="mb-0 text-muted"> Reported Links</p>
                            <DarkSkeleton count={1} width={'35%'} />
                            <p className="mb-0 text-muted"> REMOVAL SUCCESS RATE</p>
                            <DarkSkeleton count={1} width={'35%'} />
                        </div>
                    </CardBody>
                </Card>
            </Col>
        )})



    const CustomerServicesQuery = useQuery({
        queryKey: ['activeServices'],
        queryFn: async () => {
            const response = await fetchAPI.get(
                process.env.REACT_APP_CUSTOMERS_MANAGEMENT_URL +
                '/analytics/customer/services/' + getCustomerId(),
                process.env.REACT_APP_CAMPAIGNS_MANAGEMENT_TOKEN
            );
            return response;
        },
        onSuccess: (data) => {
            setActiveServices(data);
        }
    })

    useEffect(() => {
        if (!data) return;
        const tmpServicesInfo = [];
        data['apps'] = appsData;
        services.forEach((service) => {
            if (Object.keys(serviceNamesMap).includes(service) && service !== 'cyberlocker' && service !== 'okru') return;
            if (service === 'apps' && main === false) return;
            tmpServicesInfo.push({
                title: service,
                reported_links: data[service]?.reported || data[serviceNamesMap[service]]?.reported || data[serviceNamesMap[service]]?.reported || 0,
                removal_rate: data[service]?.removal_rate || data[serviceNamesMap[service]]?.removal_rate || data[serviceNamesMap[service]]?.removal_rate || 0,
                photo: serviceLogos[service],
                isActive: activeServices.includes(serviceNamesMap[service]) || activeServices.includes(service) ? true : false
            })
        })
        setServicesInfo(tmpServicesInfo)
    }, [data,activeServices, appsData])
    return (
        <React.Fragment>
            <h4 className="mb-4">Services Insights For The Current Year</h4>
            <div className="cards-container">
                <Row className="d-flex justify-content-center"> {/* Add Row component */}
                    {
                    !data ? LoadingCards : (servicesInfo).map((item, key) => (
                        <Col lg={3} key={key}>
                            <Card >
                                <CardBody className="p-2 pt-4 pd-4" style={{ minHeight: "210px", width:'100%', marginRight: "5px" }} >
                                    <div className="text-center mb-3">
                                        <img src={item.photo} alt="" className="avatar-sm" />
                                        <h5 className="mt-4 mb-2 font-size-15">{item.title.toUpperCase()}</h5>
                                        <p className="mb-0 text-muted"><b>{item.reported_links}</b> Reported Links</p>
                                        <p className="mb-0 text-muted"><b>{item.removal_rate % 1 === 0 ? Math.round(item.removal_rate) : item.removal_rate}%</b> REMOVAL SUCCESS RATE</p>
                                        {item.isActive && <Badge color="success" className="mt-3">Active Service</Badge>}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                </Row> {/* Close Row component */}
            </div>
        </React.Fragment>
    );
}

export default ServicesData;