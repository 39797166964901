import React from 'react'
import ReactLoading from 'react-loading';

function Loading() {
  return (
    <div className="page-content" >
      <div className="container-fluid" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <ReactLoading type={'bubbles'} color={'cyan'} className={'loading'} />
      </div>
    </div>
  )
}

export default Loading