import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import Loading from "components/Common/loading/Loading";
import CampaignLoadingCard from "./helpers/loading/CampaignLoadingCard";

export function TopCampaigns({
  topCampaignsData
}) {

  const LoadingCards = Array(5).fill(0).map((_, i) => {
    return (
      <CampaignLoadingCard key={i}/> 
    )
  })

  return (
    <Row className="mb-4" style={{ margin: 0, justifyContent: 'space-evenly' }}>
      <Col lg={12}>
        <h4 className="mb-4">Top Campaigns</h4>
      </Col>
      {
        topCampaignsData !== null && topCampaignsData !== undefined ?  Object.entries(topCampaignsData).length ? topCampaignsData.map(campaign => (
          <Col lg={2} key={campaign.campaign_id} style={{ flex: '0 0 20%' }}>
            <Link to={`/campaigns/${campaign.campaign_id}`} style={{
              color: 'inherit',
            }}>
              <Card
                className="my-card"
                style={{
                  height: "95%",
                  borderRadius: "5px",
                  cursor: "pointer"
                }}
              >
                <img
                  src={campaign.campaign_poster || "https://www.myhappyenglish.com/x9walos9f/uploads/2013/05/placeholder1.png"}
                  className="card-img-top"
                  alt="..."
                  style={{
                    maxWidth: "100%", // Set the width to 100% to ensure it takes up the full width of the container
                    height: "100%", // Set a fixed height for the image
                    objectFit: "cover", // This will scale the image while maintaining its aspect ratio
                    borderRadius: "5px",
                    borderTopLeftRadius: "5px",
                    objectFit: "cover"
                  }}
                />
                <CardBody>
                  <p
                    className="card-title"
                    style={{
                      fontSize: "11px"
                    }}
                  >
                    {campaign.campaign_name.toUpperCase()}
                  </p>
                  <h2>{campaign.reported} </h2>
                  <p className="card-text">Reported Links</p>
                </CardBody>
              </Card>
            </Link>
          </Col>
        )) : <h3 className="text-center" style={{
          height: '200px',
        }}>No Data</h3>
        : <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}>
          {LoadingCards}
        </div>
      }
    </Row>
  );
}
