import React, { useState } from "react"
import { Col, Container, Row } from "reactstrap";
import { useQuery } from '@tanstack/react-query';
import fetchAPI from 'helpers/fetcher/fetchClient';
import { Campaigns } from "./Campaigns"
import Loading from "components/Common/loading/Loading"

function CampaignsModal() {

  const customerId = localStorage.getItem("customerId");

  const fetchTopCampaigns = async () => {
    const data = await fetchAPI.get(
      process.env.REACT_APP_CAMPAIGNS_MANAGEMENT_URL +
      '/campaigns?page=*&fields=id,campaign_name,campaign_status&customer_id=' + customerId + '&relations=campaign_poster_photo_id,customer_campaign_services',
      process.env.REACT_APP_CAMPAIGNS_MANAGEMENT_TOKEN
    );
    return data.results; // Assuming the top_campaigns array is returned in the response.
  };

  const topCampaignsQuery = useQuery(['allCampaigns'], fetchTopCampaigns);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl="12">
              {
                topCampaignsQuery.isLoading ? (
                  <Loading />
                ) :
                  (<Campaigns topCampaignsData={topCampaignsQuery.data} isLoading={topCampaignsQuery.isLoading} />)
              }
            </Col>
          </Row>


        </Container>
      </div>
    </React.Fragment>

  )
}

export default CampaignsModal