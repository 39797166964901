import React from 'react'
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Container,
} from "reactstrap";
import FileCard from './FileCard';
import { useQuery } from '@tanstack/react-query';
import Loading from 'components/Common/loading/Loading';
import fetchAPI from 'helpers/fetcher/fetchClient';

const not_accepted_formats = []

function FilesSection({ files }) {

    const data = files.map(file => {
        return {
            name: file.url.split('/').pop(),
            url: file.url,
        }
    })

    const content = data.map((file, index) => {
        return (
            <FileCard
                key={index}
                file_url={file.url}
                file_name={file.name}
            />
        )
    })


    return (
        <Container fluid={true}>
            <Row>
                <Col xl={12}>
                    <Card>
                        <CardBody className='d-flex flex-wrap'>
                            {content}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default FilesSection