import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Row } from "reactstrap";
import DarkSkeleton from "../MainComponents/helpers/loading/DarkSkeleton";

const CampaignDetailGeneral = ({ campaignData }) => {

  if (!campaignData) {
    return <DarkSkeleton height={350} />;
  }

  return (
    <Row>
      <Col className="col-7">
        <Card style={{ maxHeight: "525px", height: "320px" }}>
          <CardBody >
            <div className="flex-grow-1 overflow-hidden d-flex align-items-center">
              <img
                src={localStorage.getItem('customerProfile')}
                alt=""
                className="avatar-md rounded-circle me-3"
                style={{
                  width: "50px",
                  height: "50px",
                }}
              />
              <h5 className="text-truncate font-size-15"> {campaignData?.campaignRightsOwner}</h5>
            </div>
            <h5 className="font-size-15 mt-4">Campaign details :</h5>
            <Row>
              <Col sm="6" xs="12" className="d-flex justify-content-start">
                <div className="mt-4">
                  <i className="mdi mdi-chevron-right text-primary me-1" />{" "}
                  <b>Campaign Name :</b> {campaignData.campaignName}
                  <br />
                  <br />
                  <i className="mdi mdi-chevron-right text-primary me-1" />{" "}
                  <b>Campaign Status :</b> {campaignData.campaignStatus === true ? "Active" : "Inactive"}
                  <br />
                  <br />
                  <i className="mdi mdi-chevron-right text-primary me-1" />{" "}
                  <b>Country :</b> {campaignData?.campaignCountry}
                  <br />
                  <br />
                  <i className="mdi mdi-chevron-right text-primary me-1" />{" "}
                  <b>Sample Of Work :</b> <a href={campaignData.sampleOfWork}
                    target="_blank"
                    rel="noreferrer"
                  >Click here</a>
                  <br />
                  <br />
                </div>
              </Col>
            </Row>
            <br />
          </CardBody>
        </Card >
      </Col>
      <Col className="col-5">
        <Card style={{ maxHeight: "525px", height: "320px", display: 'flex', alignItems: 'center' }}>
          <img
            src={campaignData.campaignPoster}
            alt=""
            style={{
              maxHeight: "525px",
              height: "320px",
              width: "100%",
              objectPosition: "center"
            }}
          />

        </Card>
      </Col>

    </Row>
  );
};

CampaignDetailGeneral.propTypes = {
  project: PropTypes.object,
};

export default CampaignDetailGeneral;
