import React from 'react';



function FileCard({ file_url, file_name=""}) {

    const handleDownload = async () => {
        try {
            const response = await fetch(file_url);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = file_name;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);

            // Release the object URL to free up resources
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    return (
        <div
            className='m-4 d-flex'
            style={{
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
            }}
            onClick={handleDownload}
        >
            <div className='avatar-sm bg-light rounded'>
                <img
                    src='https://cdn-icons-png.flaticon.com/512/2557/2557778.png'
                    alt=''
                    className='img-thumbnail'
                />
            </div>
            <div style={{
                 maxWidth: '80px'
            }}>
                <h6 className='font-16'>{file_name}</h6>
            </div>
        </div>
    );
}

export default FileCard;
