import React from 'react'
import { Row, Card, CardBody, CardTitle, Nav, NavItem, NavLink } from "reactstrap";
import LineColumnArea from "../../apex/LineColumnArea";
import { da } from 'date-fns/locale';
import Loading from 'components/Common/loading/Loading';
import DarkSkeleton from './helpers/loading/DarkSkeleton';
function PerServiceGraph({ data, socialMedia = false }) {
    const [selectedOption, setSelectedOption] = React.useState('detected')

    const handleDataChange = (type) => {
        setSelectedOption(type);
    };

    const socialMediaServices = [
        'facebook',
        'instagram',
        'twitter',
        'youtube',
        'tiktok'
    ]  
    const servicesData = {}

    if (data) {
    
    Object.keys(data).forEach((key) => {
        if (socialMedia) {
            if (socialMediaServices.includes(key)) {
                servicesData[key] = data[key]
            }
        }
        else{
            if (!socialMediaServices.includes(key)) {
                servicesData[key] = data[key]
            }
        }
    })
}


    return (
        <Row>
            <Card className="my-card">
                <CardTitle style={{ marginLeft: "10px", marginTop: "15px" }}>
                    <h4 className="card-title mb-4">{socialMedia ? 'Social Media Statistics' : 'Statistics Per Service'}</h4>
                    <Nav pills>
                        <NavItem>
                            <NavLink
                                className={selectedOption === 'detected' ? 'active' : 'inactive'}
                                onClick={() => handleDataChange('detected')}
                                href="#"
                            >
                                Reported
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={selectedOption === 'removed' ? 'active' : 'inactive'}
                                onClick={() => handleDataChange('removed')}
                                href="#"
                            >
                                Removed
                            </NavLink>
                        </NavItem>
                    </Nav>
                </CardTitle>
                <CardBody>
                    {data ? <LineColumnArea data={servicesData} selectedOption={selectedOption} /> : <DarkSkeleton height={350} />}
                </CardBody>
            </Card>
        </Row>
    )
}

export default PerServiceGraph