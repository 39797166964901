import React from 'react'
import { Row, Col, Card, CardBody } from "reactstrap";
import DarkSkeleton from './helpers/loading/DarkSkeleton';

function OverAllInsights({ data, overall = false }) {

    let totalDetectedLinks = data?.reported
    let totalRemovedLinks = data?.removed
    let removalSuccessRate = data?.removal_rate

    const reports = [
        { title: overall ? "Reported" : "Reported Links", iconClass: "bx-link", description: totalDetectedLinks !== null && totalDetectedLinks !== undefined ? totalDetectedLinks : null },
        { title: overall ? "Removed" : "Removed Links", iconClass: "bx-unlink", description: totalRemovedLinks !== null && totalRemovedLinks !== undefined ? totalRemovedLinks : null },
        {
            title: overall ? "Removal rate" : "Removal rate",
            iconClass: "bx-trophy",
            description: (removalSuccessRate !== null && removalSuccessRate !== undefined) ? removalSuccessRate + "%" : null,
        },
    ];


    return (
        <Row>
            {/* Reports Render */}
            {reports.map((report, key) => (
                <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid my-card">
                        <CardBody>
                            <div className="d-flex justify-content-between">
                                <div className="flex-grow-1">
                                    {report.title}
                                    <h4 className="mb-0">{report.description !== null ? report.description : <DarkSkeleton animation='wave' width={'50%'} />}</h4>
                                </div>
                                <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                    <span className="avatar-title rounded-circle bg-primary">
                                        <i
                                            className={
                                                "bx " + report.iconClass + " font-size-24"
                                            }
                                        ></i>
                                    </span>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            ))}
        </Row>
    )
}

export default OverAllInsights