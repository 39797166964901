import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { Card, CardBody } from 'reactstrap';
import DarkSkeleton from './helpers/loading/DarkSkeleton';

function PerCustomerStats({ data }) {
    if (!data) {
        return <div className='mb-3'>
            <DarkSkeleton height={450} />
        </div>
    }

    let series = [{
        name: "Reported",
        data: Object.values(data).map((item) => item.reported)
    }, {
        name: "Removed",
        data: Object.values(data).map((item) => item.removed)
    }]

    const options = {
        chart: {
            type: 'bar',
            height: 450
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: Object.keys(data),
        },
        yaxis: {
            title: {
                text: 'Number of Links'
            }
        },
        fill: {
            opacity: 1,
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val + " Links"
                }
            }
        }
    };



    return (
        <Card >
            <CardBody>
                <ReactApexChart
                    options={options}
                    series={series}
                    type="bar"
                    height={450}
                />
            </CardBody>
        </Card>
    )
}

export default PerCustomerStats