import React, { useState, useEffect } from 'react'
import { Form, FormFeedback, Input, Label, Row, Col} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import { getUserId } from 'helpers/auth/getUserId'
import Select from 'react-select';
import fetchAPI from 'helpers/fetcher/fetchClient'
import { toast as toastr } from "react-toastify";
import CampaignDropDown from 'pages/DropDowns/CampaignDropDown'
import ServicesDropDown from 'pages/DropDowns/ServicesDropDown'
import { useQuery } from '@tanstack/react-query'
import Loading from 'components/Common/loading/Loading';
import generateExcel from './generateExcel';


function getCurrentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed, so +1
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}${month}${day}${hours}${minutes}${seconds}`;
}

function GenerateReport() {
    const [reportId, setReportId] = useState(1);
    const [loading, setLoading] = useState(false);
    const [campaignName, setCampaignName] = useState(null);
    const [hideForm, setHideForm] = useState(false);

    useEffect(() => {
        // Additional conditions can be added if needed
        if (reportId === 1) {
          setHideForm(true);
        } else {
          setHideForm(false);
        }
      }, [reportId]);

    const fetchCustomerInfo = async () => {
        const response = await fetchAPI.get(
            process.env.REACT_APP_CUSTOMERS_MANAGEMENT_URL +
            '/analytics/customer/details/?user_id=' + getUserId(),
            process.env.REACT_APP_CAMPAIGNS_MANAGEMENT_TOKEN
        );
        return response;
    };

    const customerInfoQuery = useQuery(
        ['customerInfo'],
        fetchCustomerInfo
    );

    const validation = useFormik({
        // enableReinitialize: use this flag when initial values need to be changed
        enableReinitialize: true,

        initialValues: {
            customer: localStorage.getItem("customerId"),
            service: "",
            report_name: "",
            start_date: "",
            end_date: "",
            service_id: "",
            campaign_id: "",
            last_updated_by_id: getUserId() || "",
        },
        validationSchema: Yup.object({
            customer: Yup.string().nullable("Please select a customer"),
            service: Yup.string().nullable(),
            report_name: Yup.string().nullable(),
            start_date: Yup.date().max(new Date(Date.now() - 86400000).toISOString().split("T")[0], "Start Date must me a date before today"),
            end_date: Yup.date().max(new Date().toISOString().split("T")[0], "End Date must me a date before tomorrow")
                .min(Yup.ref('start_date'), "End Date can't be before the Start Date."),
            service_id: Yup.string().nullable(),
            campaign_id: Yup.string().nullable(),
        }),
        onSubmit: async (values, { resetForm }) => {
            setLoading(true);
            let { value, name } = validation.values.campaign_id === '' || validation.values.campaign_id === null || validation.values.campaign_id === undefined ? reportsAvailable[0] : reportsAvailable[1];
            toastr.info("Generating report, Please wait");
            fetchAPI.get(process.env.REACT_APP_REPORTS_GENERATION_API + value, '').then((res) => {
                // check if status is 204
                if (res == "No Links" || res.length == 0) {
                    toastr.info("No Links");
                    return;
                }
                toastr.success("Downloading report, Please wait");
                generateExcel(res, name);
                // var link = document.createElement("a");
                // link.download = name;
                // link.href = res.file;
                // document.body.appendChild(link);
                // link.click();
                // document.body.removeChild(link);
            }).catch((err) => {
                toastr.error(err.message);
            }).finally(() => {
                setLoading(false);
                resetForm();
            });
        },
    });

    const customStyles = {
        option: (base) => {
            return {
                ...base,
                backgroundColor: "white",
            };
        }
    };

    let reportsAvailable = [
        {
            id: 1,
            label: "Reported & removed links - per service",
            value: `/new-reports?response_type=json&report_type=reported_removed_links_per_service&start_date=${validation.values.start_date}&end_date=${validation.values.end_date}${validation.values.service != 10000 ? "&service_id=" + validation.values.service : "&service_id=*"}&customer_id=${validation.values.customer}`,
            name: `${customerInfoQuery.data?.customer_name}_${validation.values.start_date}_${validation.values.end_date}_reported_removed_links_per_service_${getCurrentDateTime()}`
        },
        {
            id: 2,
            label: "Reported & removed links - per campaign",
            value: `/new-reports?response_type=json&report_type=reported_removed_links_per_campaign&customer_id=${validation.values.customer}&campaign_id=${validation.values.campaign_id}&start_date=${validation.values.start_date}&end_date=${validation.values.end_date}${validation.values.service != 10000 ? "&service_id=" + validation.values.service : "&service_id=*"}`,
            name: `${customerInfoQuery.data?.customer_name}_${validation.values.start_date}__${validation.values.end_date}_${getCurrentDateTime()}`
        }
        
    ];

    const servicesReports =
        ['Google', 'Bing', 'Dailymotion', 'Ok.ru', 'Vimeo', 'Telegram', 'Ugc-source', 'Youtube', 'Facebook', 'Instagram', 'Twitter'];

    if (customerInfoQuery.isLoading) {
        return <Loading />;
    }

    return (
        <div className="page-content">
            <div className="container-fluid" style={{ minHeight: '500px' }}>
                <Form
                    onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                    }}
                >
                    <Row 
                    hidden={true}
                    >
                        <div className="mb-3">
                            <Select
                                onChange={selectedOption => {
                                    setReportId(selectedOption.id);
                                    return validation.setFieldValue("report_name", selectedOption.label);
                                }
                                }
                                className={`basic-multi-select ${validation.touched.report_name && validation.errors.report_name ? "invalid" : ""}`}
                                name='report_name'
                                id='report_name'
                                defaultValue={reportsAvailable.find(option => option.id === 1)}
                                classNamePrefix="select"
                                onBlur={validation.handleBlur}
                                styles={customStyles}
                                aria-invalid={
                                    validation.touched.report_name && validation.errors.report_name ? true : false
                                }
                                required
                                options={reportsAvailable}
                                
                                 />
                            {validation.touched.report_name && validation.errors.report_name ? (
                                <div className="error-message">{validation.errors.report_name}</div>
                            ) : null}
                        </div>
                    </Row>
                    <Row>
                        <div className="mb-3">
                            <h2>Extract statistical Report</h2>
                            <p>The statistical report comprises all reported links, indicating their removal status and when applicable, the number of views</p>
                        </div>
                    </Row>
                    {[1, 2, 3, 4, 5, 6].includes(reportId) && <Row>
                        <Col sm={4}>
                        <div className="mb-3">
                            <ServicesDropDown onChange={selectedOption => {
                                return validation.setFieldValue("service", selectedOption?.value || "")
                            }}
                                onBlur={validation.handleBlur}
                                value={validation.values.service || ""}
                                validation={validation}
                                filter={servicesReports}
                                isAll={true}
                                exclude={["facebook", "twitter", "instagram", "youtube","tiktok"]}
                            />
                        </div>
                        </Col>
                    </Row>}
                    {[1,5].includes(reportId) && <Row>
                        
                            <Col sm={4}>
                                <div className="mb-3">
                                <CampaignDropDown 
                                    onChange={selectedOption => {
                                        setCampaignName(selectedOption.label)
                                        return validation.setFieldValue("campaign_id", selectedOption.value)
                                        }}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.campaign_id || ""}
                                    validation={validation}
                                    customer_id={validation.values.customer}
                                    optional={true}
                                />
                                </div>
                            </Col>
                            
                        
                    </Row>}
                    {[1, 2, 4, 5, 6].includes(reportId) && <>
                        <Row>
                        <Col sm={4}>
                            <div className="mb-3">
                                <Label htmlFor="description">Start Date</Label>
                                <Input
                                    type="date"
                                    className="form-control"
                                    id="start_date"
                                    placeholder="Choose Report Start Date"
                                    name='start_date'
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.start_date || ""}
                                    invalid={
                                        validation.touched.start_date && validation.errors.start_date ? true : false
                                    }
                                />
                                {validation.touched.start_date && validation.errors.start_date ? (
                                    <FormFeedback type="invalid">{validation.errors.start_date}</FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                        </Row>
                        <Row>
                        <Col sm={4}>
                            <div className="mb-3">
                                <Label htmlFor="description">End Date</Label>
                                <Input
                                    type="date"
                                    className="form-control"
                                    id="end_date"
                                    placeholder="Choose Report End Date"
                                    name='end_date'
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.end_date || ""}
                                    invalid={
                                        validation.touched.end_date && validation.errors.end_date ? true : false
                                    }
                                />
                                {validation.touched.end_date && validation.errors.end_date ? (
                                    <FormFeedback type="invalid">{validation.errors.end_date}</FormFeedback>
                                ) : null}
                            </div>
                        </Col>
                        </Row>
                    </>
                    }
                    <div>
                        <button type="submit" className="btn btn-primary w-md" disabled={loading}>
                            Submit
                        </button>
                    </div>
                </Form>

            </div>
        </div>

    )
}

export default GenerateReport