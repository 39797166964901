import PropTypes from "prop-types";
import React, { useEffect } from "react";
import withRouter from "components/Common/withRouter";
import {
  changeLayout,
  changeLayoutMode,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
  showRightSidebarAction
} from "../../store/actions";

// Layout Related Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import RightSidebar from "../CommonForBoth/RightSidebar";
import { useQuery } from "@tanstack/react-query";
//redux
import { useSelector, useDispatch } from "react-redux";
import fetchAPI from "helpers/fetcher/fetchClient";
import { Alert } from "reactstrap";

// function to convert timestamp with timezone to local time
function convertTZ(date, tzString = "UTC") {
  let convertedDate = new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
  return convertedDate.toLocaleString();
}


const Layout = props => {
  const dispatch = useDispatch();
  const [show, setShow] = React.useState(false);

  const SystemMessageQuery = useQuery({
    queryKey: ["system-message"],
    enabled: false,
    retry: 3,
    queryFn: async () => {
      return await fetchAPI.get(
        process.env.REACT_APP_SYSTEM_UTILS_URL + "/system-messages"
      )
    },
    onSuccess: (data) => {
      if (!data || data === 'No Links') return
      const fetched = JSON.parse(localStorage.getItem(`system-message-fetched-${data.id}`, false))
      if (data && !fetched) {
        setShow(true)
        
        // delete all previous system messages
        Object.keys(localStorage).forEach(key => {
          if (key.startsWith("system-message-fetched-")) {
            localStorage.removeItem(key)
          }
        })
        localStorage.setItem(`system-message-fetched-${data.id}`, JSON.stringify(true))
      }
    }
  })

  const {
    isPreloader,
    leftSideBarThemeImage,
    layoutWidth,
    leftSideBarType,
    topbarTheme,
    showRightSidebar,
    leftSideBarTheme,
    layoutModeType
  } = useSelector(state => ({
    isPreloader: state.Layout.isPreloader,
    layoutModeType: state.Layout.layoutModeType,
    leftSideBarThemeImage: state.Layout.leftSideBarThemeImage,
    leftSideBarType: state.Layout.leftSideBarType,
    layoutWidth: state.Layout.layoutWidth,
    topbarTheme: state.Layout.topbarTheme,
    showRightSidebar: state.Layout.showRightSidebar,
    leftSideBarTheme: state.Layout.leftSideBarTheme,
  }));

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);


  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      dispatch(changeSidebarType("condensed", isMobile));
    } else if (leftSideBarType === "condensed") {
      dispatch(changeSidebarType("default", isMobile));
    }
  };

  //hides right sidebar on body click
  const hideRightbar = (event) => {
    var rightbar = document.getElementById("right-bar");
    //if clicked in inside right bar, then do nothing
    if (rightbar && rightbar.contains(event.target)) {
      return;
    } else {
      //if clicked in outside of rightbar then fire action for hide rightbar
      dispatch(showRightSidebarAction(false));
    }
  };

  /*
  layout  settings
  */

  useEffect(() => {
    //init body click event fot toggle rightbar
    document.body.addEventListener("click", hideRightbar, true);
    SystemMessageQuery.refetch();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(changeLayout("vertical"));
  }, [dispatch]);

  useEffect(() => {
    if (leftSideBarTheme) {
      dispatch(changeSidebarTheme(leftSideBarTheme));
    }
  }, [leftSideBarTheme, dispatch]);

  useEffect(() => {
    if (layoutModeType) {
      dispatch(changeLayoutMode(layoutModeType));
    }
  }, [layoutModeType, dispatch]);

  useEffect(() => {
    if (leftSideBarThemeImage) {
      dispatch(changeSidebarThemeImage(leftSideBarThemeImage));
    }
  }, [leftSideBarThemeImage, dispatch]);

  useEffect(() => {
    if (layoutWidth) {
      dispatch(changeLayoutWidth(layoutWidth));
    }
  }, [layoutWidth, dispatch]);

  useEffect(() => {
    if (leftSideBarType) {
      dispatch(changeSidebarType(leftSideBarType));
    }
  }, [leftSideBarType, dispatch]);

  useEffect(() => {
    if (topbarTheme) {
      dispatch(changeTopbarTheme(topbarTheme));
    }
  }, [topbarTheme, dispatch]);



  return (
    <React.Fragment>
      {/* <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div> */}

      <div id="layout-wrapper">
        <Header toggleMenuCallback={toggleMenuCallback} />
        <Sidebar
          theme={leftSideBarTheme}
          type={leftSideBarType}
          isMobile={isMobile}
        />
        <div className="main-content">
          {!SystemMessageQuery.isLoading && SystemMessageQuery.data && SystemMessageQuery.data !== 'No Links' && (
          <div style={{
            position: "fixed",
            top: "80px",
            left: '50%',
            transform: 'translate(-30%, 0)',
            zIndex: 1000
          }}>
            <Alert isOpen={show} toggle={() => {
                        setShow(false)
                      }}
                      color={SystemMessageQuery.data.message_type}
                      >
                        {SystemMessageQuery.data.message}
                        <br />
                        Starting from {convertTZ(SystemMessageQuery.data.start_at)} to {convertTZ(SystemMessageQuery.data.end_at)}
                        <br />
                        Thanks for your Colaboration.
                        <br />
                        RightsHero Tech Team

            </Alert>
          </div>) }
          {props.children}
        </div>
        {/* <Footer /> */}
      </div>
      {showRightSidebar ? <RightSidebar /> : null}
    </React.Fragment>
  );
};

Layout.propTypes = {
  changeLayoutWidth: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarThemeImage: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.any,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarThemeImage: PropTypes.any,
  leftSideBarType: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any,
};

export default withRouter(Layout);
