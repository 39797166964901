import React from 'react';
import { Link } from "react-router-dom"

import {
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap"
import DarkSkeleton from './helpers/loading/DarkSkeleton';


function TopRemovals({ todayRemovedLinks = null, thisMonthRemovedLinks = null, topRemovalServices = null }) {

  const serviceColorLookup = {
    Facebook: '#2a569d',
    Instagram: '#C13584',
    Twitter: '#1DA1F2',
    Youtube: '#FF0000',
    Tiktok: '#000000',
  };

  return (
    <Col md={12}>
      <Card style={{height:"450px"}}>
        <CardBody>
          <div className="d-flex flex-wrap">
            <div className="ms-2">
              <h5 className="card-title mb-3">Top Removals For The Current Year</h5>
            </div>
          </div>
          <Row className="text-center">
            <Col xs={6}>
              <div className="mt-3">
                <p className="mb-1">Today</p>
                <h5>{todayRemovedLinks !== null && todayRemovedLinks !== undefined ? todayRemovedLinks : <DarkSkeleton count={1} width={'30%'} />}</h5>
              </div>
            </Col>

            <Col xs={6}>
              <div className="mt-3">
                <p className="mb-1">This Month</p>
                <h5>
                  {thisMonthRemovedLinks !== null && thisMonthRemovedLinks !== undefined ? thisMonthRemovedLinks : <DarkSkeleton count={1} width={'30%'} />}
                </h5>
              </div>
            </Col>
          </Row>

          <hr />

          <div>
            <ul className="list-group list-group-flush">
              {topRemovalServices ?
                Object.entries(topRemovalServices).map(([service, data]) => (
                  <li className="list-group-item" key={service}>
                    <div className="py-2">
                      <h5 className="font-size-14">
                        {service} <span className="float-end">{data}%</span>
                      </h5>
                      <div className="progress animated-progess progress-sm">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: `${data}%`, backgroundColor: serviceColorLookup[service] || '#66BC0F'}}
                          aria-valuenow={data}
                          aria-valuemin="0"
                          aria-valuemax="100"
                          
                        ></div>
                      </div>
                    </div>
                  </li>
                )) : Array(3).fill(0).map((_, i) => {
                  return (
                    <li className="list-group-item" key={i}>
                      <div className="py-2">
                        <h5 className="font-size-14">
                          <DarkSkeleton count={1} />
                        </h5>
                        <div className="progress animated-progess progress-sm">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: `${(3-i) * 15}%`, backgroundColor: '#66BC0F'}}
                            aria-valuenow={50}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </li>
                  )
                })}
            </ul>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}

export default TopRemovals;
