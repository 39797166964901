export const refreshToken = async () => {
    return new Promise(async function (resolve, reject) {
        let refreshTokenValue = localStorage.getItem('refreshToken')
        if (refreshTokenValue) {
            let response = await fetch(process.env.REACT_APP_USERS_MANAGEMENT_URL + '/token/refresh/', {
                method: "POST",
                body: JSON.stringify({ "refresh": refreshTokenValue }),
                headers: {
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                }
            })
            let result = await response.json();
            if(response.status == 401){
                if(result.code == "token_not_valid"){
                    reject(result.detail)
                }
            }
            resolve(result.access);
        }
    })
}