import React from "react";
import { Navigate } from "react-router-dom";
import { decryptText } from "helpers/crypto";
import {  postAdminLogin,} from "helpers/fakebackend_helper";
import { useLocation } from 'react-router-dom';

const Authmiddleware = (props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const uid = searchParams.get('uid');
  const token = searchParams.get('token');

  if(uid && token){
    const uid_decrypted = decryptText(uid);
    const token_decrypted = decryptText(token);
    localStorage.setItem("accessToken",token_decrypted);
    const response =  postAdminLogin(uid_decrypted);
  }else if (!localStorage.getItem("authUser")) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }
  return (<React.Fragment>
    {props.children}
  </React.Fragment>);
};

export default Authmiddleware;
