import getChartColorsArray from 'components/Common/ChartsDynamicColor';
import React from 'react'
import ReactApexChart from 'react-apexcharts';
import { Col } from 'reactstrap';
import DarkSkeleton from './helpers/loading/DarkSkeleton';

function TopPiratedDomains({ topDomains, dataColors, isLoading=false }) {

    const spineareaChartColors = getChartColorsArray(dataColors);

    let domain_names = [];
    let domain_count = [];

    if (topDomains !== undefined && topDomains !== null) {
        Object.entries(topDomains).map(([domain, data]) => (
            domain_names.push(domain),
            domain_count.push(data)
        ));
    }


    const series = [
        {
            name: "Detected Links",
            data: domain_count,
        },
    ]
    const options = {
        chart: {
            toolbar: {
                show: true,
            },
        },
        plotOptions: {
            bar: {
                horizontal: true,
            },
        },
        dataLabels: {
            enabled: false,
        },

        colors: spineareaChartColors,
        grid: {
            borderColor: "#f1f1f1",
        },
        xaxis: {
            categories: domain_names,
            
        },
    }

    return (
        <>
            <Col lg="12">
                <h5 className="mb-4">Top Pirated Domains For The Current Year</h5>
            </Col>
            <Col lg="12">
                {isLoading ? <DarkSkeleton height={'300px'}/> : <ReactApexChart options={options} series={series} type="bar" height="350" />}
            </Col>
        </>

    )
}

export default TopPiratedDomains
