import React from "react";
import ReactDOM from 'react-dom/client';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools} from "@tanstack/react-query-devtools"
import store from "./store";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient(
//   {
//   defaultOptions:{
//     queries:{
//       staleTime: 1000*60*15 // data is considered fresh for 15 minutes, and then it refetches data 
//     }
//   }
// }
)
root.render(
    <Provider store={store}>
      <React.Fragment>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <App />
            <ReactQueryDevtools />
          </QueryClientProvider>
        </BrowserRouter>
      </React.Fragment>
    </Provider>
);

serviceWorker.unregister()
